export const localeTranslationsModule = {
  "locale": "ru",
  "translations": {
    "app-name": "Rugram",
    "app-logo": "/assets/img/logo.svg",
    "dialog.yes-button": "Да",
    "dialog.no-button": "Нет",
    "dialog.ok-button": "OK",
    "dialog.delete-button": "Удалить",
    "dialog.cancel-button": "Отменить",
    "dialog.understand-button": "Понятно",
    "dialog.save-button": "Сохранить",
    "dialog.next-button": "Далее",
    "dialog.back-button": "Назад",
    "dialog.cancel-download-button": "Отменить загрузку",
    "app-bar.create-project-button": "Создать проект",
    "app-bar.account-button": "Личный кабинет",
    "app-bar.sign-in-button": "Войти в аккаунт",
    "app-bar.my-projects-button": "Мои проекты",
    "app-bar.image-generation-button": " AI изображения",
    "app-bar.admin-button": "Админка",
    "mobile-lock-text1": "Сервис пока не работает на мобильных устройствах",
    "mobile-lock-text2": "Пожалуйста, зайдите с компьютера",
    "app-bar.profile.menu.profile-button": "Настройки",
    "app-bar.profile.menu.pricing-button": "Тарифы",
    "app-bar.profile.menu.dark-theme-button": "Тёмная тема",
    "app-bar.profile.menu.logout-button": "Выход",
    "app-bar.profile.menu.language-button": "Язык",
    "app-bar.profile.menu.promocode-button": "Ввести промокод",
    "app-bar.profile.menu.start-onboarding-button": "Пройти обучение",
    "app-bar.profile.menu.knowledge-base-button": "База знаний",
    "app-bar.menu-mobile.close-button": "Закрыть окно",
    "payment.error.cant-get-url": "Не удалось получить ссылку оплаты",
    "components.select.no-selected": "Выбрать",
    "components.select.error-selected": "Выберите из выпадающего списка значение",
    "canvas.error": "Ваш браузер не поддерживает canvas.",
    "undefined": "",
    "welcome.header": "Метранпаж обновился!",
    "welcome.text": "Теперь вы можете редактировать любые текстовые стили, настраивать поля, управлять колонтитулами и многое другое.",
    "welcome.button": "Изучить обновления",
    "common.copy-text": "Скопировать текст",
    "common.text-copied-to-clipboard-success": "Текст скопирован в буфер обмена",
    "common.text-copied-to-clipboard-failed": "Ошибка при копировании текста в буфер обмена",
    "common.promocode-copied-to-clipboard-success": "Промокод скопирован в буфер обмена",
    "common.promocode-copied-to-clipboard-failed": "Ошибка при копировании промокода в буфер обмена",
    "maintenance.title1": "Мы проводим технические работы на сервере. Пожалуйста, повторите попытку позднее 🙏",
    "maintenance.title2": "С заботой о вас,",
    "maintenance.title3": "Ваш Rugram",
    "welcome.update-news-202404.header": "Метранпаж обновился!",
    "welcome.update-news-202404.text": "Мы упросили монетизацию сервиса, теперь работать станет еще проще! Все текущие балансы пользователей пересчитаны и стали выгоднее!<br><br><a href=\"https://metranpage.com/monetization_update\" target=\"_blank\">Подробнее в блоге</a>",
    "welcome.update-news-202404.button": "Перейти к тарифам",
    "welcome.update-ig-12-24.header": "Повтор лица в Метранпаже!",
    "welcome.update-ig-12-24.text": "Создавайте уникальные изображения с вашим лицом или лицами близких! Просто загрузите фото, выберите стиль и опишите желаемый <br>образ – и получите яркие и запоминающиеся генерации!",
    "welcome.update-ig-12-24.button": "Попробовать",
    "welcome.update-news-ig.header": "А теперь – Обложки!",
    "welcome.update-news-ig.text": "Мы выпустили новый функционал, теперь вы можете генерировать изображения и создавать уникальные обложки для книг. Воспользуйтесь интуитивно понятным интерфейсом, чтобы выбрать стиль и оформление. Сделайте каждую книгу незабываемой с Метранпаж!",
    "welcome.update-news-ig.button": "Попробовать",
    "welcome.update-news-ig-2.header": "Метранпаж обновился!",
    "welcome.update-news-ig-2.text": " +20 новых стилей генерации изображений, улучшение и расширение изображений, а также обновленный редактор обложек с новыми функциями.  <br><br>Попробуйте новинки прямо сейчас! ",
    "welcome.update-news-ig-2.button": "Попробовать",
    "welcome.update-news-ig-3.header": "Новый уровень точности!",
    "welcome.update-news-ig-3.text": "Мы обновили режим генерации изображений для более точного повторения сюжетов. Для вас доступно 20 новых стилей, с которыми ваши работы станут еще разнообразнее.  <br><br>Откройте новые творческие возможности!",
    "welcome.update-news-ig-3.button": "Попробовать",
    "welcome.webinar-1.header": "Мастер-класс: как сделать обложку \n<br>для вашего бестселлера? ",
    "welcome.webinar-1.today.text": "Бесплатный вебинар сегодня в 20:00 по МСК.",
    "welcome.webinar-1.tomorrow.text": "Бесплатный вебинар завтра в 20:00 по МСК.",
    "welcome.webinar-1.list.1": "Как работают клише в дизайне",
    "welcome.webinar-1.list.2": "Влияние жанра на обложку",
    "welcome.webinar-1.list.3": "Практика: создаём обложку по аннотации",
    "welcome.webinar-1.author-name": "Кир Витковский",
    "welcome.webinar-1.author-text": ", основатель «Метранпаж»",
    "welcome.webinar-1.gift": "🎁 Подарок каждому участнику!",
    "welcome.webinar-1.button": "Принять участие",
    "welcome.update-news-ig-4.header": "Встречайте новый Креативный <br>режим генерации!",
    "welcome.update-news-ig-4.text": " +34 новых стиля и улучшенное качество <br>на базе Midjourney. <br><br>Новая функция «Повтор стиля» в Базовом режиме: загружайте свои изображения и генерируйте картинки на их основе!",
    "welcome.update-news-ig-4.button": "Попробовать",
    "premium-mark": "Premium",
    "new-mark": "New",
    "soon-mark": "Скоро",
    "soon-text-notification": "Этот функционал появится в следующих обновлениях",
    "common.pluralize.left": "{value, plural, zero {осталось} one {остался} two {осталось} few {осталось} many {осталось} other {осталось} } ",
    "common.pluralize.add": "{value, plural, zero {добавлено} one {добавлен} two {добавлено} few {добавлено} many {добавлено} other {добавлено} } ",
    "common.select-component.not-selected": "Не выбрано",
    "admin.nav.cover-templates": "Шаблоны обложек",
    "admin.cover-templates.button.setDefault": "Выбрать по умолчанию",
    "cover.shadow": "Тень",
    "cover.offset": "Смещение",
    "cover.direction": "Направление",
    "cover.blur": "Размытие",
    "cover.transparency": "Прозрачность",
    "cover.shadow-fill": "Заливка тени",
    "cover.shadow-color": "Цвет тени",
    "user.profile.nav.main": "Основные данные",
    "user.profile.nav.notifications": "Уведомления",
    "user.profile.nav.rewards": "Награды",
    "user.profile.nav.password": "Пароль",
    "user.profile.nav.tariff-and-payments": "Тариф и оплата",
    "user.profile.nav.contacts": "Почта",
    "user.profile.nav.logout": "Выйти",
    "user.profile.nav.developer-settings": "Инструменты разработчика",
    "user.profile.main.first-name": "Имя",
    "user.profile.main.last-name": "Фамилия",
    "user.profile.main.role": "Сфера деятельности",
    "user.profile.main.delete-account-confirmation": "Вы уверены что хотите удалить аккаунт?",
    "user.profile.main.delete-account-enter-confirmation": "Чтобы удалить аккаунт, введите фразу:",
    "user.profile.main.delete-account-confirmation-phrase": "Удалить",
    "user.profile.main.save-changes": "Сохранить изменения",
    "user.profile.main.saved": "Сохранено",
    "user.profile.password.delete-profile-error": "Ошибка удаления профиля",
    "user.profile.password.old-password": "Старый пароль",
    "user.profile.password.new-password": "Новый пароль",
    "user.profile.password.new-password-repeat": "Повторите новый пароль",
    "user.profile.password.change-password": "Изменить пароль",
    "user.profile.password.wrong-old-password": "Старый пароль введён неверно",
    "user.profile.password.changed": "Пароль изменен",
    "user.profile.contacts.email": "Email",
    "user.profile.contacts.save-changes": "Сохранить изменения",
    "user.profile.contacts.verification-email-was-send": "Письмо с подтверждением было отправлено\nна вашу почту",
    "user.profile.contacts.email-not-verified": "Почта не верифицированна",
    "user.profile.contacts.verify-email": "Отправить письмо",
    "user.profile.tariff-and-payments.nav-tabs.tariff": "Тариф ",
    "user.profile.tariff-and-payments.nav-tabs.payments-info": "Платёжная информация",
    "user.profile.tariff-and-payments.nav-tabs.payments-history": "История платежей",
    "user.profile.tariff-and-payments.delete-card-confirmation": "Вы уверены что хотите удалить карту?",
    "user.profile.tariff-and-payments.card-select": "Выбрать",
    "user.profile.tariff-and-payments.card-active": "Активная",
    "user.profile.tariff-and-payments.add-card": "Добавить карту",
    "user.profile.tariff-and-payments.add-card-error": "Не удалось добавить карту",
    "user.profile.tariff-and-payments.expire": "Истекает",
    "user.profile.tariff-and-payments.left": "осталось",
    "user.profile.tariff-and-payments.upgrade-tariff": "Улучшить тариф",
    "user.profile.tariff-and-payments.need-help": "Нужна дополнительная помощь?",
    "user.profile.tariff-and-payments.contact-support": "Написать в поддержку",
    "user.profile.tariff-and-payments.trial-period-end": "Бесплатный период истёк",
    "user.profile.tariff-and-payments.trial-period": "бесплатного периода",
    "user.profile.tariff-and-payments.connect-tariff": "Подключите тарифный план",
    "user.profile.tariff-and-payments.tariff.ask-question": "Напишите свой вопрос",
    "user.profile.tariff-and-payments.payment-history.date": "Дата",
    "user.profile.tariff-and-payments.payment-history.description": "Направление платежа",
    "user.profile.tariff-and-payments.payment-history.details": "Детализация",
    "user.profile.tariff-and-payments.payment-history.amount": "Стоимость",
    "user.profile.tariff-and-payments.payment-history.status": "Статус",
    "user.profile.tariff-and-payments.payment-history.status.created": "Создан",
    "user.profile.tariff-and-payments.payment-history.status.completed": "Оплачен",
    "user.profile.tariff-and-payments.payment-history.status.success": "Оплачен",
    "user.profile.tariff-and-payments.payment-history.status.failed": "Ошибка",
    "user.auth.login-title": "Войти",
    "user.auth.divider-text": "Или",
    "user.auth.login.social.google": "Продолжить с Google",
    "user.auth.login.social.vk": "Продолжить с VK",
    "user.auth.registration-title": "Регистрация",
    "user.auth.restore-password-title": "Забыли пароль?",
    "user.auth.restore-password.login-button": "Войти в личный кабинет",
    "user.auth.new-password-title": "Учётная запись",
    "user.auth.email-placeholder": "эл. почта",
    "user.auth.username-placeholder": "имя пользователя",
    "user.auth.firstname-placeholder": "имя",
    "user.auth.lastname-placeholder": "фамилия",
    "user.auth.password-placeholder": "Пароль",
    "user.auth.password-confirmation-placeholder": "Повторите пароль",
    "user.auth.role-placeholder": "выберите роль:",
    "user.auth.error-selected-role": "Выберите роль",
    "user.auth.login-button": "Войти",
    "user.auth.register-button": "Зарегистрироваться",
    "user.auth.register-switch": "Зарегистрироваться",
    "user.auth.login-switch": "Войти",
    "user.auth.already-registered-hint": "Уже есть аккаунт?",
    "user.auth.not-registered-hint": "Еще не с нами?",
    "user.auth.wrong-creds-error": "Неверный email или пароль",
    "user.auth.generic-error": "Произошла неизвестная ошибка. Повторите запрос позднее.",
    "user.auth.cant-register-error": "Не удалось зарегистрировать пользователя. Повторите запрос позднее.",
    "user.auth.email-not-unique-error": "Email должен быть уникальным",
    "user.auth.passwords-are-different-error": "Пароли не совпадают",
    "user.auth.wrong-login-method-error": "Для этой учетной записи используется другой метод входа",
    "user.auth.restore-password-prompt": "Забыли пароль?",
    "user.auth.restore-password-description": "Для восстановления пароля укажите свою электронную почту",
    "user.auth.restore-password-request-button": "Восстановить пароль",
    "user.auth.restore-password-back-button": "Назад",
    "user.auth.restore-email-sent-text": "Ссылка для восстановления пароля отправлена на ваш e-mail",
    "user.auth.restore-pass-email-not-found-error": "Аккаунт не найден, введите другой e-mail",
    "user.auth.restore-cant-restore-for-social-error": "Невозможно сбросить пароль, так как для этой учетной записи используется вход через социальую сеть",
    "user.auth.reset-password-description": "Введите новый пароль для вашего аккаунта",
    "user.auth.change-password-button": "Сменить пароль",
    "user.auth.restore-pass-token-not-found-error": "Не удалось изменить пароль. Запросите новую ссылку на восстановление пароля",
    "user.auth.field-required-error": "Заполните поле",
    "user.auth.login-registration.title": "Создайте аккаунт",
    "user.auth.login-registration.description": "Попробуйте все функции приложения и взаимодействуйте с сообществом",
    "user.auth.login-registration.button": "Вход / Регистрация",
    "user.auth.login-registration.modal.title": "Выполните вход в аккаунт",
    "user.auth.login-registration.modal.description": "Для получения доступа ко всем возможностям сервиса <br>зарегистрируйтесь или авторизуйтесь в системе.",
    "user.auth.social-login.continue-with": "Продолжить с",
    "user.auth.cant-login-vk": "Не удалось авторизоваться через VK",
    "user.auth.cant-login-google": "Не удалось авторизоваться через Google",
    "user.auth.cant-login-one-time-token": "Не удалось авторизоваться ",
    "user.auth.sign-in-with-google": "Войти с помощью Google",
    "user.auth.switch-language": "Switch to English version",
    "user.auth.agreement-part1": "Я согласен с ",
    "user.auth.agreement-part2": "офертой и политикой обработки данных",
    "user.auth.agreement-url": "https://lk.rugram.me/assets/download/user_agreement.docx",
    "user.auth.agreement-error": "Для регистрации необходимо ваше согласие",
    "user.auth.promo-agree": "Я хочу подписаться на новости сервиса",
    "user.profile.title": "Профиль",
    "user.profile.edit-form1-header": "Основные данные",
    "user.profile.edit-form2-header": "Изменить пароль",
    "user.profile.email-placeholder": "эл. почта",
    "user.profile.firstname-placeholder": "имя",
    "user.profile.old-password-placeholder": "старый пароль",
    "user.profile.new-password-placeholder": "Новый пароль",
    "user.profile.new-password-confirmation-placeholder": "Повторите пароль",
    "user.profile.default-language": "Язык по умолчанию",
    "user.profile.save-button": "Сохранить",
    "user.profile.logout-button": "Выйти из системы",
    "user.profile.delete-account-button": "Удалить аккаунт",
    "user.profile.verify-alert1": "Пожалуйста,",
    "user.profile.verify-alert2": "подтвердите",
    "user.profile.verify-alert3": "почту",
    "user.profile.updated-successfully": "Ваша учетная запись обновлена",
    "user.profile.please-update-account-with-role": "Пожалуйста, укажите вашу роль в <a href=\"/account\" style=\"text-decoration: underline;\">профиле</a>, это поможет нам в развитии сервиса",
    "user.profile.enable-browser-notifications-button": "Включить уведомления в браузере",
    "user.profile.browser-notifications-accepted": "Вы включили уведомления в браузере!",
    "user.verify-email": "Пожалуйста, подтвердите электронную почту",
    "user.verify-email-send": "Письмо с подтверждением отправлено Вам на электронную почту",
    "user.verify-email-success": "Электронная почта подтверждена",
    "user.verify-email-user-not-found-error": "Пользователь не найден",
    "user.verify-email-token-not-correct-error": "Неправильный токен",
    "user.verify-email-token-not-expired-error": "Действие ссылки закончено",
    "user.verify-email-send-header": "Пожалуйста, подтвердите почту",
    "user.verify-email-send-body": "Мы отправили письмо с подтверждением на адрес {$email}. Пожалуйста, проверьте свой почтовый ящик.<br>\nНе нашли письмо? Проверьте папку \"Спам\" или нажмите кнопку ниже, чтобы отправить письмо повторно.",
    "user.verify-email-send-button": "выслать снова",
    "user.verify-email-send-timer1": "(через ",
    "user.verify-email-send-timer2": " сек.)",
    "user.auth.error.wrong-email-format": "Неверный формат email",
    "user.profile.verify-email-not-verified": "Почта не подтверждена. ",
    "user.profile.verify-email-send-email": "Подтвердить",
    "user.profile.save-email-after-changed": "Вы изменили почту, сохраните изменения и подтвердите новую почту",
    "user.roles.publisher": "Представитель издательства",
    "user.roles.author": "Автор",
    "user.roles.designer": "Дизайнер-верстальщик",
    "user.roles.student": "Студент",
    "user.verify-error": "Ошибка при верификации, пожалуйста обновите страницу",
    "user.profile.change-password-not-correct-error": "Введен неверный пароль",
    "user.profile.change-password-is-equal-to-current-error": "Введенный пароль уже используется",
    "user.profile.notifications.title": "Ваши уведомления",
    "user.profile.notifications.filter.all": "Все",
    "user.profile.notifications.filter.important": "Важные",
    "user.profile.notifications.filter.news": "Новости",
    "user.profile.notifications.filter.moderation": "Модерация",
    "user.profile.notifications.card.moderation-success.reward": "на Ваш счет.",
    "user.profile.notifications.card.image-moderation-success.title": "Изображение прошло проверку",
    "user.profile.notifications.card.image-moderation-success.description-1": "Ваше",
    "user.profile.notifications.card.image-moderation-success.description-action": "изображение",
    "user.profile.notifications.card.image-moderation-success.description-2": "опубликовано в сообществе.",
    "user.profile.notifications.card.image-moderation-success.description-3": "Вам начислено 10 токенов.",
    "user.profile.notifications.card.image-moderation-fail.title": "Изображение не прошло проверку",
    "user.profile.notifications.card.image-moderation-fail.description-1": "Ваше",
    "user.profile.notifications.card.image-moderation-fail.description-action": "изображение",
    "user.profile.notifications.card.image-moderation-fail.description-2": "не опубликовано в сообществе по причине:",
    "user.profile.notifications.card.image-moderation-fail.description-without-reason": "не опубликовано в сообществе.",
    "user.profile.notifications.card.cover-moderation-success.title": "Обложка прошла проверку",
    "user.profile.notifications.card.cover-moderation-success.description-1": "Ваша",
    "user.profile.notifications.card.cover-moderation-success.description-action": "обложка",
    "user.profile.notifications.card.cover-moderation-success.description-2": "опубликована в сообществе.",
    "user.profile.notifications.card.cover-moderation-success.description-3": "Вам начислено 10 токенов.",
    "user.profile.notifications.card.cover-moderation-fail.title": "Обложка не прошла проверку",
    "user.profile.notifications.card.cover-moderation-fail.description-1": "Ваша",
    "user.profile.notifications.card.cover-moderation-fail.description-action": "обложка",
    "user.profile.notifications.card.cover-moderation-fail.description-2": "не опубликована в сообществе по причине:",
    "user.profile.notifications.card.promocode-activate.title": "Поздравляем! Промокод «{$promocodeTitile}» применен",
    "user.profile.notifications.card.promocode-activate.subscription.description-1": "Подписка на тариф",
    "user.profile.notifications.card.promocode-activate.subscription.description-2": "на",
    "user.profile.notifications.card.promocode-activate.subscription.description-3": "активирована",
    "user.profile.notifications.card.promocode-activate.tokens.description": "уже на Вашем балансе",
    "user.profile.notifications.card.promocode-activate.discount.description-1": "Успейте его использовать, чтобы воспользоваться лучшими условиями!",
    "user.profile.notifications.card.promocode-activate.discount.description-2": "Скидка",
    "user.profile.notifications.card.promocode-activate.discount.description-3": "действует до",
    "user.profile.notifications.card.promocode-expire.title": ", чтобы использовать промокод",
    "user.profile.notifications.card.promocode-expire.description-1": "Последний шанс! Используйте ваш",
    "user.profile.notifications.card.promocode-expire.description-action": "промокод",
    "user.profile.notifications.card.promocode-expire.description-2": "до завтрашнего дня, чтобы не упустить скидку!",
    "user.profile.notifications.card.registration.title": "Добро пожаловать!",
    "user.profile.notifications.card.registration.description": "Мы рады приветствовать вас в сообществе профессионалов и любителей книг. Теперь у вас есть доступ  к мощным инструментам для автоматической вёрстки печатных и электронных книг.",
    "user.profile.notifications.card.email-verification.title": "Подтвердите email",
    "user.profile.notifications.card.email-verification.description-1": "Пожалуйста, подтвердите ваш ",
    "user.profile.notifications.card.email-verification.description-action": "email",
    "user.profile.notifications.card.email-verification.description-2": ", чтобы начать работу.",
    "user.profile.notifications.card.trial-period-activate.title-1": "Вам доступно",
    "user.profile.notifications.card.trial-period-activate.title-2": "пробного периода",
    "user.profile.notifications.card.trial-period-activate.description-1": "Пробный период закончится ",
    "user.profile.notifications.card.trial-period-ending.title": "До окончания пробного периода",
    "user.profile.notifications.card.trial-period-ending.3-days-left.description-1": "Не упустите возможность перейти на платный",
    "user.profile.notifications.card.trial-period-ending.3-days-left.description-2": "и продолжить работу в сервисе без ограничений.",
    "user.profile.notifications.card.trial-period-ending.description-action": "тариф",
    "user.profile.notifications.card.trial-period-ending.description": "Пробный период закончится",
    "user.profile.notifications.card.trial-period-ending.1-day-left.description-1": "Завтра истекает ваш бесплатный пробный период. Вам станет доступен только 1 проект, и будет ограничен доступ к функционалу сервиса. Перейдите на платный",
    "user.profile.notifications.card.trial-period-ending.1-day-left.description-2": "и наслаждайтесь всеми возможностями сервиса.",
    "user.profile.notifications.card.subscription-activate.title-1": "Вы активировали тарифный план ",
    "user.profile.notifications.card.subscription-activate.title-2": "срок действия",
    "user.profile.notifications.card.subscription-activate.description-1": "Подписка закончится ",
    "user.profile.notifications.card.subscription-ending.title-1": "До окончания Вашей подписки",
    "user.profile.notifications.card.subscription-ending.title-2": "осталось",
    "user.profile.notifications.card.subscription-ending.description-1": "Пожалуйста, убедитесь, что на Вашей карте достаточно средств для следующего платежа.",
    "user.profile.notifications.card.subscription-ending.description-2": "Подписка закончится ",
    "user.profile.notifications.card.book-layout.preview.title-1": "Превью Вашей книги  ",
    "user.profile.notifications.card.book-layout.preview.title-2": "готово ",
    "user.profile.notifications.card.book-layout.preview.description-1": "Превью книги  ",
    "user.profile.notifications.card.book-layout.preview.description-2": "готово ",
    "user.profile.notifications.card.book-layout.final.title-1": "Вёрстка Вашей книги  ",
    "user.profile.notifications.card.book-layout.final.title-2": "готова ",
    "user.profile.notifications.card.book-layout.final.description-1": "Вёрстка книги  ",
    "user.profile.notifications.card.book-layout.final.description-2": "готова ",
    "user.profile.notifications.card.book-without-cover.title-1": "У вашей книги",
    "user.profile.notifications.card.book-without-cover.title-2": "нет обложки",
    "user.profile.notifications.card.book-without-cover.description-action": "Создайте",
    "user.profile.notifications.card.book-without-cover.description": "её прямо сейчас!",
    "user.profile.notifications.card.low-balance.title": "Внимание! У вас ",
    "user.profile.notifications.card.low-balance.description-1": "Пополните",
    "user.profile.notifications.card.low-balance.description-action": "баланс,",
    "user.profile.notifications.card.low-balance.description-2": "чтобы продолжить использование всех функций сервиса без ограничений.",
    "user.profile.notifications.card.connect-telegram.title": "Забирайте 75 бонусных токенов!",
    "user.profile.notifications.card.connect-telegram.description-1": "Не упустите шанс! Подпишитесь на наш",
    "user.profile.notifications.card.connect-telegram.description-action": "Telegram-канал",
    "user.profile.notifications.card.connect-telegram.description-2": "прямо сейчас и получите в подарок 75 бонусных токенов.",
    "user.profile.notifications.card.connect-telegram.description-3": "Бонусные токены помогут вам быстрее освоить все возможности нашего сервиса и стать настоящим мастером верстки и дизайна!",
    "user.profile.notifications.card.add-tokens.title": "Токены добавлены на Ваш баланс",
    "user.profile.notifications.card.add-tokens.description-1": "на Ваш баланс",
    "user.profile.notifications.card.add-tokens.purpose.connect-telegram": "За подписку на Telegram канал",
    "user.profile.notifications.card.add-tokens.purpose.bonus": "В качестве бонуса",
    "user.profile.notifications.card.like.title": "Вам поставили лайк",
    "user.profile.notifications.card.like.image.description-1": "Вашему ",
    "user.profile.notifications.card.like.image.action": "изображению",
    "user.profile.notifications.card.like.image.description-2": "поставили лайк",
    "user.profile.notifications.card.like.cover.description-1": "Ваша",
    "user.profile.notifications.card.like.cover.action": "обложка",
    "user.profile.notifications.card.like.cover.description-2": "получила лайк",
    "user.profile.notifications.card.webinar.title": "Как сделать обложку для вашего бестселлера?",
    "user.profile.notifications.card.webinar.active.action": "Смотрите",
    "user.profile.notifications.card.webinar.active.date-today": "сегодня",
    "user.profile.notifications.card.webinar.active.date-tomorrow": "завтра",
    "user.profile.notifications.card.webinar.active.description": "в 20:00 по МСК бесплатный мастер-класс",
    "user.profile.notifications.card.webinar.ended.description": "Вебинар закончился",
    "user.profile.socials.telegram.modal.title-1": "Получите",
    "user.profile.socials.telegram.modal.title-2": "за подписку на наш Telegram",
    "user.profile.socials.telegram.modal.conditions-title": "Выполните простые действия:",
    "user.profile.socials.telegram.modal.check-title": "Проверка будет доступна после выполнения",
    "user.profile.socials.telegram.modal.check-subscription-to-channel": "Проверить подписку",
    "user.profile.socials.telegram": "Telegram",
    "user.profile.socials.telegram.link-account": "Привязать аккаунт Telegram",
    "user.profile.socials.telegram.subscription-to-channel": "Подписаться на канал",
    "user.profile.socials.telegram.link-account.error": "Ошибка подключения Telegram",
    "user.profile.socials.disconnect-account-confirmation.telegram": "Вы уверены что хотите отвязать Telegram?",
    "user.profile.socials.disconnect-account-confirmation.telegram.error": "Произошла ошибка, при отключении аккаунта Telegram. Попробуйте позднее",
    "user.profile.socials.telegram.token-expire-error": "Аккаунт Telegram не подключен. Ссылка устарела. Попробуйте еше раз",
    "user.profile.socials.telegram.account-already-connected-error": "Аккаунт Telegram уже подключен",
    "user.profile.socials.telegram.account-already-connected-to-another-account-error": "Аккаунт Telegram уже подключен к другой учетной записи",
    "user.profile.socials.telegram-profile-connected": "Аккаунт Telegram подключен",
    "user.profile.socials.telegram-profile-not-found-error": "Аккаунт Telegram не подключен",
    "user.profile.socials.telegram.subscription-to-channel-verified": "Подписка на канал подтверждена",
    "user.profile.socials.telegram.check-subscription-to-channel-error": "Произошла ошибка проверки подписки на канал",
    "user.profile.socials.vk": "ВКонтакте",
    "user.profile.socials.vk.link-account": "Привязать профиль VK",
    "user.profile.socials.not-connected": "Не подключен",
    "user.profile.socials.connected": "Подключен",
    "user.profile.socials.disconnect-button": "Отвязать от профиля",
    "user.profile.socials.disconnect-account-confirmation-phrase": "Отвязать",
    "user.profile.rewards-list.title": "Получайте токены за активности в сообществе",
    "user.profile.rewards.title": "История начислений",
    "user.profile.rewards.subscribe-to-telegram-channel": "Подписка на Telegram",
    "user.profile.rewards.like.give": "Поставленный лайк",
    "user.profile.rewards.like.take": "Полученный лайк",
    "user.profile.rewards.moderation-image": "Опубликованное изображение",
    "user.profile.rewards.moderation-cover": "Опубликованная обложка",
    "user.profile.rewards.like.give.notification": "За поставленный лайк Вам ",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.title": "Подпишитесь на наш Telegram канал",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-1": "Привяжите аккаунт Telegram",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-1-action": "в профиле",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-2": "Подпишитесь",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.task-2-action": "на канал Telegram",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.button": "Проверить",
    "user.profile.reward-tasks.subscribe-to-telegram-channel.you-get-text": "и получите",
    "user.profile.reward-tasks.completed": "Выполнено",
    "user.profile.reward-tasks.daily.likes.give": "Награда за каждый поставленный лайк",
    "user.profile.reward-tasks.daily.likes.take": "Награда за каждый полученный лайк",
    "user.profile.reward-tasks.daily.per-day": "в день",
    "books.error.cant-load-books": "Не удалось загрузить список книг. Пожалуйста, попробуйте позже.",
    "books.error.cant-load-book": "Не удалось загрузить книгу",
    "books.error.cant-create-book": "Не удалось создать книгу. Пожалуйста, попробуйте позже.",
    "books.error.cant-edit-book": "Не удалось сохранить изменения. Пожалуйста, попробуйте позже.",
    "books.error.markup-create-error": "Произошла ошибка при создании макета. Мы уже работаем над исправлением ошибки, пожалуйста, попробуйте позже.",
    "books.error.preview-create-error": "Произошла ошибка при создании файлов предпросмотра. Мы уже работаем над исправлением ошибки, пожалуйста, попробуйте позже.",
    "books.error.final-create-error": "Произошла ошибка во время верстки книги. Мы уже работаем над исправлением ошибки, пожалуйста, попробуйте позже.",
    "books.error.process-error": "Произошла непредвиденная ошибка {$book-title} {$book-id}",
    "books.notification.book-saved": "Сохранено",
    "books.list.empty": "У вас еще нет проектов",
    "books.error.cant-copy-book": "Не удалось дублировать книгу",
    "books.error.cant-delete-book": "Не удалось удалить книгу",
    "books.breadcrumb.new-project": "Новый проект",
    "books.breadcrumb.upload-step": "Загрузка",
    "books.breadcrumb.template-step": "Шаблон",
    "books.breadcrumb.markup-step": "Разметка",
    "books.breadcrumb.layout-step": "Макет",
    "books.breadcrumb.result-step": "Результат",
    "books.breadcrumb.build-step": "Создание макета",
    "books.breadcrumbs.cover": "Обложка",
    "books.list.title": "Мои проекты",
    "books.list.hint": "Со­зда­вая но­вый про­ект вы со­зда­е­те про­ект из­да­ния с уни­каль­ным ди­зай­ном, ли­бо про­ект шаб­ло­на, на­при­мер, для се­рии книг, объ­еди­нен­ных об­щим ди­зай­ном.\n\nВ рам­ках од­но­го про­ек­та мож­но со­зда­вать и хра­нить мно­жест­во книг.",
    "books.list.edit-panel.general": "Общие сведения",
    "books.list.edit-panel.additional": "Выбор языка и версии",
    "books.list.edit-panel.format": "Формат издания",
    "books.list.edit-panel.edition-notice": "Выходные данные",
    "books.book.status-prefix": "Статус:",
    "books.book.status-docx": "создание макета",
    "books.book.status-preview": "создание предпросмотра",
    "books.book.status-final": "создание книги",
    "books.book.status-final-ready": "книга готова",
    "books.book.status-previews-ready": "предпросмотр готов",
    "books.book.status-markup-ready": "стили определены",
    "books.book.status-no-styles": "стили не определены",
    "books.book.action-docx": "Создаю макет…",
    "books.book.action-preview": "Создаю макет…",
    "books.book.action-final": "Верстаю книгу…",
    "books.book.type-title": "Выберите версию издания",
    "books.book.type-book-title": "Книга",
    "books.book.type-book-image": "./assets/img/book.png",
    "books.book.type-newspaper-title": "Газета (скоро)",
    "books.book.type-newspaper-image": "./assets/img/newspaper-stroke.png",
    "books.book.type-booklet-title": "Буклет (скоро)",
    "books.book.type-booklet-image": "./assets/img/booklet-stroke.png",
    "books.book.type-presentation-title": "Презентация (скоро)",
    "books.book.type-presentation-image": "./assets/img/presentation-stroke.png",
    "books.book.error-cannot-set-type": "Не удалось сохранить книгу. Попробуйте позднее",
    "books.book.final-indd-link-title": "Рабочие файлы (.indd)",
    "books.book.final-pdf-link-title": "Файл предпросмотра (.pdf)",
    "books.book.final-pdf-for-print-link-title": "Файл для печати (.pdf)",
    "books.book.final-epub-link-title": "Epub",
    "books.book.final-all-title": "Скачать все (.zip)",
    "books.book.download-cover": "Скачать обложку (.png)",
    "books.book.create-button": "Новый проект",
    "books.book.download-button": "Скачать",
    "books.book.edit-button": "Редактировать",
    "books.book.edit-cover": "Редактировать обложку",
    "books.book.edit-book-data": "Редактировать данные книги",
    "books.book.edit-book": "Редактировать книгу",
    "books.book.upload-book": "Загрузить книгу",
    "books.book.copy-book": "Дублировать",
    "books.book.delete-button": "Удалить",
    "books.book.delete-dialog-confirmation": "Подтвердите действие",
    "books.book.delete-dialog-title": "Вы действительно хотите удалить проект",
    "books.book.delete-project-dialog-button": "Удалить проект",
    "books.book.action-docx-title": "Документ размечается",
    "books.book.action-docx-description": "Это займет какое-то время",
    "books.book.action-preview-title": "Книга верстается",
    "books.book.action-preview-description": "Это займет какое-то время",
    "books.book.action-final-title": "Книга верстается",
    "books.book.action-final-description": "Это займет какое-то время",
    "books.book.create-cover-title": "Создать <br>обложку",
    "books.book.print-settings.paper-type": "Бумага",
    "books.book.print-settings.cover-type": "Обложка",
    "books.book.print-settings.cover-lamination-type": "Ламинация обложки",
    "books.book.print-settings.print-chromaticity": "Цветность печати",
    "books.book.print-settings.print-run": "Тираж (от 30 шт.)",
    "books.book.print-settings.estimated-price": "Предварительная стоимость тиража",
    "books.book.print-settings.button.request-price-calculation": "Запросить точный расчет",
    "books.book.print-settings.price-calculation": "Расчет печати",
    "books.book.print-settings.constraint-finalize-first": "Завершите процесс вёрстки проекта, чтобы получить доступ к калькулятору",
    "books.book.print-settings.constraint-pages": "Доступен для проектов, количество страниц в которых не менее 38",
    "books.book.print-settings.constraint-size-format": "Автоматический предварительный расчет доступен только для формата A5",
    "books.book.print-settings.constraint-format": "Доступен для печатных проектов",
    "books.book.print-settings.placeholder.phone-number": "Номер телефона",
    "books.book.print-settings.placeholder.delivery-address": "Укажите адрес доставки",
    "books.book.print-settings.button.request": "Запросить",
    "books.book.print-settings.thanks": "Спасибо!",
    "books.book.print-settings.request-explanation": "Мы обработаем ваш запрос в ближайшее время и ответим по электронной почте",
    "books.book.print-settings.button.excellent": "Отлично",
    "books.book.print-settings.contact-form-header": "Точный расчет",
    "books.book.print-settings.redirect-button-title": "Перейти к печати",
    "books.new-book.header": "Новая книга",
    "books.new-book.genre": "Жанр книги",
    "books.new-book.create-button": "Создать",
    "books.new-book.save-button": "Сохранить",
    "books.new-book.cancel-button": "Отмена",
    "books.format.title": "Формат",
    "books.format.square": "Квадрат",
    "books.format.custom": "Произвольный",
    "books.format.width-label": "Ширина мм.",
    "books.format.width-placeholder": "ширина",
    "books.format.height-label": "Высота мм.",
    "books.format.height-placeholder": "высота",
    "books.format.size-error": "Размер страницы не может быть меньше 52мм.",
    "books.format.vertical-format-error": "Укажите, пожалуйста, вертикальный формат вашего проекта",
    "books.info.title-placeholder": "Название",
    "books.info.author-placeholder": "Автор",
    "books.info.year-placeholder": "Год",
    "books.info.city-placeholder": "Город",
    "books.upload.sample-options": "Вы также можете создать",
    "books.upload.drop-text1": "Перетащите сюда",
    "books.upload.drop-text2": "или",
    "books.upload.drop-text2-upload": "загрузите",
    "books.upload.drop-text3": "файл в формате .docx",
    "books.upload.drop-text4": "или",
    "books.upload.drop-text5-select": "выберите",
    "books.upload.drop-text6": "ранее загруженный файл",
    "books.upload.process-hint": "Размечаю текст рукописи…",
    "books.upload.download-sample-text-button": "Скачать образец текста .docx",
    "books.upload.helper-title": "Как подготовить текст к вёрстке?",
    "books.upload.helper-title-hide-button": "Свернуть",
    "books.upload.helper-advice1-b": "Применяйте только стандартные стили",
    "books.upload.helper-advice1": "Не создавайте свои и не переименовывайте существующие. Если нужного стиля нет — оставьте эту часть без стиля, его можно добавить на следующем шаге.",
    "books.upload.helper-advice2-b": "Не применяйте эффект обтекания текстом",
    "books.upload.helper-advice2": "Лучше всего разместить картинки отдельными строками в максимально допустимом разрешении.",
    "books.upload.helper-instruction-title": "Инструкция",
    "books.upload.helper-instruction-subtitle": "для загрузки изображений в Microsoft Word без потери качества",
    "books.upload.helper-step": "Шаг",
    "books.upload.helper-instruction-step-win1": "Заходим в параметры",
    "books.upload.helper-instruction-step-win1-image": "./assets/img/win1.png",
    "books.upload.helper-instruction-step-win2": "Во вкладке «Дополнительно» переходим к разделу «Размер и качество изображений». Выбираем «330 пикселей на дюйм».",
    "books.upload.helper-instruction-step-win2-image": "./assets/img/win2.png",
    "books.upload.helper-instruction-step-mac1": "Заходим в параметры",
    "books.upload.helper-instruction-step-mac1-image": "./assets/img/mac1.png",
    "books.upload.helper-instruction-step-mac2": "Нажимаем кнопку «Изменить»",
    "books.upload.helper-instruction-step-mac2-image": "./assets/img/mac2.png",
    "books.upload.helper-instruction-step-mac3": "В макете нет текста",
    "books.upload.helper-instruction-step-mac3-image": "./assets/img/mac3.png",
    "books.upload.not-docx-error": "Загрузите, пожалуйста, вашу рукопись в формате .docx",
    "books.upload.cant-read-file-error": "Не удалось прочитать файл. Пожалуйста, удалите содержание, выходную информацию, декоративные элементы из вашего .docx файла и попробуйте снова. Если в вашем документе есть сгруппированные объекты, пожалуйста, разгруппируйте их. При повторной ошибке обратитесь в поддержку: info@rugram.me",
    "books.upload.hint1.image": "/assets/images/upload-no-scan.svg",
    "books.upload.hint1.title": "Не отправляйте в вёрстку файл с отсканированными страницами других книг",
    "books.upload.hint1.description": "Сервис не сможет определить текст и сверстать его по всем правилам. Текст должен находится в вашем документе в редактируемом виде.",
    "books.upload.hint2.image": "/assets/images/upload-no-toc.svg",
    "books.upload.hint2.title": "Удалите из вашей рукописи содержание и оглавление",
    "books.upload.hint2.description": "На основе заголовков в тексте сервис соберет актуальное содержание с нумерацией страниц.",
    "books.upload.hint3.image": "/assets/images/upload-no-decor.svg",
    "books.upload.hint3.title": "Оставьте в вашем документе только контент вашего издания (текст, изображения, таблицы)",
    "books.upload.hint3.description": "Все декоративные элементы, колонтитулы и нумерация страниц будут мешать при создании макета и вёрcтки.",
    "books.upload.use-sample-doc": "Попробовать тестовый документ",
    "books.upload.use-sample-doc-2": "Тестовый документ",
    "books.upload.use-empty-doc": "Создать пустой документ",
    "books.upload.use-empty-doc-2": "Пустой документ",
    "books.upload.original-filename-hint": "Загружен документ:",
    "books.upload.tables-in-multi-column-template-error.title": "Внимание!",
    "books.upload.tables-in-multi-column-template-error.text": "В выбранном шаблоне нельзя использовать файлы с таблицами. <br>Измените шаблон или удалите таблицы из документа",
    "books.upload.tables-in-multi-column-template-error.button": "Понятно",
    "books.info-modal.header1": "Новый проект",
    "books.info-modal.header1-edit": "Информация о проекте",
    "books.info-modal.header2": "Версия",
    "books.info-modal.book-type-print": "печатная",
    "books.info-modal.book-type-epub": "электронная",
    "books.info-modal.enter-title-hint": "Пожалуйста, введите название книги",
    "books.info-modal.enter-author-hint": "Пожалуйста, введите автора книги",
    "books.info-modal.at-least-one-book-type-expected-hint": "Нужно выбрать один из вариантов",
    "books.template.select-template-heading": "Выбор шаблона",
    "books.template.format-custom": "Другой",
    "books.template.select-template-format-width": "Ширина мм.",
    "books.template.select-template-format-height": "Высота мм.",
    "books.template.select-template.select-button": "Выбрать",
    "books.template.select-template.details-button": "Подробнее",
    "books.template.select-template.empty-template-title": "Создать с нуля",
    "books.template.select-template.empty-template-description": "Создать с нуля",
    "books.template.select-template.tag.editable": "Редактируемый",
    "books.template.select-template.tag.non-editable": "Нередактируемый",
    "books.template.select-template.tag.blocked": "Заблокированный",
    "books.template.select-template.info.tables-in-document": "Документ содержит таблицы",
    "books.template.select-template.warning-selected-non-editable-template": "Настройки формата не доступны, т.к. выбран нередактируемый шаблон",
    "books.template.select-template.warning-on-select-non-editable-template": "При выборе нередактируемого шаблона, Вам не будут доступны настройки полей, колонтитулов, выбор шрифта и настройки стиля",
    "books.template.select-template.warning-reset-styles": "Все стили, отсутствующие в шаблоне, будут заменены стилем основного текста",
    "books.template.select-template.template-not-suitable-for-uploaded-document": "Выбранный шаблон не подходит для загруженного файла. Пожалуйста удалите таблицы или выберите другой шаблон",
    "books.template.template-info.fonts": "Шрифты:",
    "books.template.template-info.fonts-headings": "Заголовков",
    "books.template.template-info.fonts-main-text": "Основного текста",
    "books.template.template-info.font-size": "Размеры текста:",
    "books.template.template-info.font-size-main-text": "Основной текст",
    "books.template.template-info.font-size-header1": "Заголовок «Раздел»",
    "books.template.template-info.font-size-header2": "Заголовок «Глава»",
    "books.template.template-info.font-size-header3": "Заголовок «Подглавка»",
    "books.template.template-info.font-size-header4": "Заголовок «Подзаголовок»",
    "books.template.template-info.font-size-units": "пт.",
    "books.template.template-info.margins": "Поля:",
    "books.template.template-info.margins-inner": "Корешковое поле",
    "books.template.template-info.margins-outer": "Внешнее поле",
    "books.template.template-info.margins-top": "Верхнее поле",
    "books.template.template-info.margins-bottom": "Нижнее поле",
    "books.template.template-info.margins-units": "мм.",
    "books.template.template-info.running-titles": "Колонтитул:",
    "books.template.template-info.running-titles-position-top": "на верхнем поле",
    "books.template.template-info.running-titles-position-bottom": "на нижнем поле",
    "books.template.template-info.running-titles-position-outside": "на внешнем поле (вертикально)",
    "books.template.template-info.page-numbers": "Нумерация страниц:",
    "books.template.template-info.page-numbers-position-top": "на верхнем поле",
    "books.template.template-info.page-numbers-position-bottom": "на нижнем поле",
    "books.template.template-info.page-numbers-position-outside": "на внешнем поле",
    "books.template.template-info.images-position": "Размещение иллюстраций:",
    "books.template.template-info.images-position-flex": "с обтеканием текста, расположение иллюстраций адаптивно, может менять свое положение от изначального положения в рукописи.",
    "books.template.template-info.images-position-fixed": "без обтекания текста, расположение иллюстраций согласно документу",
    "books.template.template-info.changes-info": "Вы можете внести изменения в шаблон на следующих шагах.",
    "books.template.template-filter.all": "Все",
    "books.template.template-filter.editable": "Редактируемые",
    "books.template.template-filter.non-editable": "Нередактируемые",
    "books.markup.sidebar-tab-text": "Текст",
    "books.markup.sidebar-tab-design": "Дизайн",
    "books.markup.sidebar.fonts-title": "Шрифты",
    "books.markup.sidebar.fonts-size-title": "Размеры заголовков",
    "books.markup.sidebar.fonts-size-additional-sidebar": "Размеры текста",
    "books.markup.sidebar.header-font": "Шрифт заголовка",
    "books.markup.sidebar.main-text-font": "Шрифт основного текста",
    "books.markup.sidebar.text-styles": "Стили текста",
    "books.markup.sidebar.colors-title": "Цвета",
    "books.markup.sidebar.colors-styles-title": "Стили цвета текста",
    "books.markup.sidebar.color-accent-title": "Акцидентный цвет",
    "books.markup.sidebar.color-primary-title": "Цвет основного текста",
    "books.markup.sidebar.color-secondary-title": "Второстепенный цвет",
    "books.markup.sidebar.color-decor-title": "Цвет плашек",
    "books.markup.sidebar.images-title": "Размещение изображений",
    "books.markup.sidebar.images-fixed": "Фиксированное",
    "books.markup.sidebar.images-flex": "Адаптивное",
    "books.markup.sidebar.images-fixed-description": "Тут текст фиксед,  надо править локализацию",
    "books.markup.sidebar.images-flex-description": "Тут текст флекс, надо править локализацию",
    "books.markup.sidebar.colors-description-display": "Выбор акцидентного цвета может придать тексту дополнительный контраст или эмоциональную окраску, делая его более привлекательным или выразительным.",
    "books.markup.sidebar.colors-description-main": "Цвет основного текста определяет его читаемость, визуальную привлекательность и способствует передаче информации в соответствии с намеченными целями документа или дизайна.",
    "books.markup.sidebar.colors-description-secondary": "Цвет второстепенного текста обычно используется для выделения дополнительной информации или комментариев, подчеркивая их важность или отличая от основного контента. Он также может служить для создания иерархии в тексте или привлечения внимания к определенным деталям.",
    "books.markup.sidebar.images-description-fixed": "Этот режим сохранит местоположение изображений согласно вашей разметке текста. Идеально подойдёт для простых изданий более консервативного формата. Без обтекания текста.",
    "books.markup.sidebar.images-description-flex": "Этот режим расставит изображения разнообразно, основываясь на структуре документа. При этом картинки могут изменить свое местоположение по тексту.",
    "books.markup.sidebar.images-description-flex-2": "С обтеканием текста.",
    "books.markup.toolbar.add-text": "Текст",
    "books.markup.toolbar.add-image": "Изображение",
    "books.markup.toolbar.add-list-unordered": "Маркированный",
    "books.markup.toolbar.add-list-ordered": "1. Нумерованный",
    "books.markup.toolbar.add-list-ordered-bkt": "1) Нумерованный",
    "books.markup.toolbar.add-list": "Список",
    "books.markup.toolbar.add-table": "Таблица",
    "books.markup.toolbar.add-page-break": "Разрыв страницы",
    "books.markup.toolbar.move-up": "Переместить выше",
    "books.markup.toolbar.move-down": "Переместить ниже",
    "books.markup.toolbar.delete": "Удалить",
    "books.markup.style-details.header": "Настройки стиля",
    "books.markup.style-details.preview-text": "Cъешь ещё этих мягких французских булок, да выпей чаю",
    "books.markup.style-details.text settings-title": "Настройки текста",
    "books.markup.style-details.leading-title": "Межстрочный интервал",
    "books.markup.style-details.tracking-title": "Межсимвольный интервал",
    "books.markup.style-details.decor-title": "Декорирование",
    "books.markup.style-details.emphasis-title": "Начертание",
    "books.markup.style-details.align-title": "Выравнивание",
    "books.markup.style-details.case-title": "Регистр",
    "books.markup.style-details.start-on-title": "Начинать с",
    "books.markup.style-details.one-on-page-title": "Один на странице",
    "books.markup.style-details.cancel": "Отмена",
    "books.markup.style-details.apply": "Применить",
    "books.markup.style-details.include-in-content": "Включить в содержание",
    "books.markup.style-details.close": "Закрыть",
    "books.markup.style-details.drop-cap-title": "Буквица",
    "books.markup.style-details.drop-cap-description": "Буквица применяется только к первому абзацу текста, который следует после стиля ",
    "books.markup.style-details.drop-cap-description-2": "и",
    "books.markup.style-details.font-size-tooltip": "Размер шрифта",
    "books.markup.style-details.leading-tooltip": "Межстрочное расстояние",
    "books.markup.style-details.tracking-tooltip": "Межбуквенное расстояние",
    "books.markup.style-details.font-size-error": "Слишком мелкий кегль текста, ваша рукопись будет начитабельна",
    "books.markup.style-details.indent-paragraph-title": "Отбивка абзацев",
    "books.markup.style-details.indent-paragraph-after-header-title": "Красная строка после заголовка",
    "books.markup.style-details.indent-paragraph-new-line-description": "Режим отбивки между абзацами, без использования красной строки.",
    "books.markup.style-details.indent-paragraph-indented-line-description": "Режим отбивки абзацев красной строкой. Красная строка не применяется к абзацам, идущим после заголовков. Если вы хотите включить красную строку везде – активируйте эту опцию.",
    "books.markup.style-details.indent-header-title": "Отбивка заголовков",
    "books.markup.add-toc-to-layout": "Включить в вёрстку",
    "books.markup.toc-position-start": "В начале книги",
    "books.markup.toc-position-end": "В конце книги",
    "books.markup.close-without-save-data": "Возможно, внесенные изменения не сохранятся.",
    "books.styles.main-text": "Основной текст",
    "books.styles.basic-information": "Основная информация",
    "books.styles.h1": "Раздел",
    "books.styles.h2": "Глава",
    "books.styles.h3": "Подглавка",
    "books.styles.h4": "Подзаголовок",
    "books.styles.decorative-header": "Декоративный заголовок",
    "books.styles.lead": "Врезка",
    "books.styles.quote": "Цитата",
    "books.styles.quote-author": "Автор цитаты",
    "books.styles.verse": "Стих",
    "books.styles.list": "Список",
    "books.styles.note": "Примечание",
    "books.styles.caption": "Подпись / дата",
    "books.styles.image": "Изображение",
    "books.styles.table": "Таблица",
    "books.styles.page-break": "Разрыв страницы",
    "books.styles.epigraph": "Эпираф",
    "books.build.action-docx": "Формирую тексты для вёрстки",
    "books.build.action-preview": "Оформляю макет, это может занять до 5 минут",
    "books.build.action-final": "Создание книги",
    "books.build.open-style-editor-button": "Редактор стилей",
    "books.build.produce-button": "Сверстать книгу",
    "books.build.hint-books-available": "{books, plural, zero {} one {Доступен один токен} two {Доступно {books} токена} few {Доступно {books} токена} many {Доступно {books} токенов} other {Доступно {books} токенов} }",
    "books.build.hint-books-available-until": "до {$date}",
    "books.build.hint-books-available-watermark": "Будет списан 1 токен.",
    "books.build.hint-books-available-no-watermark": "Будет списано 2 токена.",
    "books.build.hint-hide-watermark": "Убрать водяной знак (2 токена)",
    "books.build.hint-preview-outdated": "В макет внесены изменения. Чтобы их увидеть, нажмите \"Обновить макет\"",
    "books.build.hint-no-preview": "Изображения предпросмотра не найдены. Чтобы создать изображения нажмите, нажмите \"Обновить макет\"",
    "books.build.update-preview-button": "Обновить макет",
    "books.build.build-sidebar-title": "Оформление",
    "books.build.build-sidebar-save-and-exit-button": "Сохранить и выйти",
    "books.build.build-sidebar-section-format": "Формат",
    "books.build.build-sidebar-section-layout": "Макет",
    "books.build.build-sidebar-section-genre": "Жанр книги",
    "books.build.build-sidebar-section-gen-type": "Воздух в макете",
    "books.build.build-sidebar-section-gen-type-less": "плотнее",
    "books.build.build-sidebar-section-gen-type-more": "больше воздуха",
    "books.build.build-sidebar-section-header-size": "Размер заголовков",
    "books.build.build-sidebar-section-main-size": "Размер основного текста",
    "books.build.build-sidebar-section-main-size-error": "Размер текста должен быть не меньше 6 и не больше 20",
    "books.build.build-sidebar-section-header-size-less": "меньше",
    "books.build.build-sidebar-section-header-size-more": "больше",
    "books.build.build-sidebar-section-fonts": "Шрифты",
    "books.build.build-sidebar-section-colorscheme": "Цветовая схема",
    "books.build.build-sidebar-section-info": "О книге",
    "books.build.recommended-settings-not-found-master-pages-error": "Не найдены рекомендованные настройки masterPages",
    "books.build.recommended-settings-not-found-make-styles-error": "Не найдены рекомендованные настройки makeStyles",
    "books.build.cant-save-book-error": "Не удалось сохранить книгу",
    "books.build.cant-start-processing-error": "Не удалось запустить создание книги",
    "books.build.cant-start-preview-error": "Не удалось запустить создание файлов предпросмотра",
    "books.layout-settings.several-columns-label": "Несколько колонок",
    "books.layout-settings.wide-margins-label": "Широкие поля",
    "books.layout-settings.columns-disabled-hint": "В документе есть таблицы. Для подобных документов доступна только одноколоночная вёрстка.",
    "books.script-settings.settings-not-found-error": "Настройки не найдены",
    "books.styles.sidebar-toc-header": "Содержание",
    "books.styles.downloading-hint": "Загружаю файл",
    "books.styles.uploading-hint": "Загружаю файл на сервер:",
    "books.styles.action-docx-hint": "Формирую тексты для вёрстки",
    "books.styles.action-preview-hint": "Оформляю макет, это может занять до 5 минут",
    "books.styles.action-final-hint": "Создание книги",
    "books.styles.main-hint1": "Сейчас нам нужно проверить правильность разметки текста. Это не этап оформления, пусть шрифты и цвета не смущают. Если в рукописи есть таблицы, текстовые сноски или содержание – пожалуйста удалите их, они пока не поддерживаются. Этот функционал появится в скором времени.",
    "books.styles.main-hint2": "Робот определил иерархию вашего текста, но пока может допускать ошибки. Проверьте пожалуйста правильность стилевой разметки вашей рукописи.",
    "books.styles.main-hint3": "Если вы хотите внести изменения, кликните в часть текста и выберите соответствующий стиль на панеле справа. Содержание в левой части экрана поможет вам быстро перемещаться по документу.",
    "books.styles.next-button": "Далее",
    "books.styles.style-delete-button": "Удалить стиль",
    "books.styles.style-only-this-time-button": "Только в этом случае",
    "books.styles.style-action-button": "Действие",
    "books.styles.cant-download-book-error": "Не удалось загрузить документ №{$book_id}",
    "books.styles.cant-process-book-error": "Не удалось обработать документ",
    "books.styles.cant-create-preview-error": "Не удалось создать файлы предпросмотра",
    "books.styles.cant-save-book-error": "Не удалось сохранить документ",
    "books.styles.need-remove-content-error": "Пожалуйста, удалите содержание",
    "books.style-editor.h1-title": "Заголовок первого уровня",
    "books.style-editor.h1-preview": "Заголовок",
    "books.style-editor.h2-title": "Заголовок второго уровня",
    "books.style-editor.h2-preview": "Подзаголовок",
    "books.style-editor.main-text-title": "Основной тест",
    "books.style-editor.main-text-preview": "В начале июля, в чрезвычайно жаркое время, под вечер, один молодой человек вышел из своей каморки, которую нанимал от жильцов в С-м переулке, на улицу и медленно, как бы в нерешимости, отправился к К-ну мосту. \nОн благополучно избегнул встречи с своею хозяйкой на лестнице. Каморка его приходилась под самою кровлей высокого пятиэтажного дома и походила более на шкаф, чем на квартиру.",
    "books.style-editor.lead-title": "Врезки",
    "books.style-editor.lead-preview": "Задача лида заключается в том, чтобы зацепить внимание читателя, вовлекая его в дальнейшее чтение.",
    "books.style-editor.quote-title": "Цитаты",
    "books.style-editor.quote-preview": "Кто хочет — ищет способ, кто не хочет — ищет причину.",
    "books.style-editor.note-title": "Примечания",
    "books.style-editor.note-preview": "Дополнительная заметка, объяснение к тексту.",
    "books.style-editor.random-styles-button": "Случайные стили",
    "books.style-editor.cancel-button": "Отменить",
    "books.style-editor.accept-button": "Сохранить",
    "books.style-editor.accept-first-time-button": "Далее",
    "books.style.text": "Текст",
    "books.style.section": "Раздел",
    "books.style.chapter": "Глава",
    "books.style.subtitle": "Подглавка",
    "books.style.subheading": "Подзаголовок",
    "books.style.lead": "Врезка",
    "books.style.annotation": "Примечание",
    "books.style.quote": "Цитата",
    "books.style.caption": "Подпись/дата",
    "books.style.picture-caption": "Подпись к картинке",
    "books.style.table-caption": "Подпись к таблице",
    "books.style.photo-caption": "Подпись к фото",
    "books.style.verse": "Стих",
    "books.markup.fix-errors": "Мы нашли проблемные места в тексте, пожалуйста, исправьте их",
    "books.markup.empty-header": "Пустой заголовок",
    "books.markup.error.wrong-font-size": "Некорректный размер текста",
    "style.text.preview": "Cъешь ещё этих мягких французских булок, да выпей чаю",
    "books.margins.top": "Верхнее поле",
    "books.margins.bottom": "Нижнее поле",
    "books.margins.outer": "Внешнее поле",
    "books.margins.inner": "Корешковое поле",
    "books.margins.gutter": "Среднее поле",
    "books.margins.padding-wide": "Широкое поле",
    "books.margins.hint": "Настройте поля",
    "books.margins.sidebar-tabs.printbook": "Печатная",
    "books.margins.sidebar-tabs.ebook": "Электронная",
    "books.margins.maket": "Макет",
    "books.margins.columns-count": "Колонки",
    "books.margins.paddings-wide": "Широкие поля",
    "books.margins.space-level": "Воздух в макете",
    "books.margins.space-level-less": "меньше",
    "books.margins.space-level-more": "больше",
    "books.margins.space-level-hint": "Управление шириной полей на основе рассчета по золотому сечению",
    "books.margins.margins-size": "Размеры полей",
    "books.margins.sidebar-tabs.ebook.info": "Для вёрстки электронной книги все готово, вы можете её сверстать уже сейчас!",
    "books.margins.sidebar-tabs.ebook.price": "Будет",
    "books.margins.warning-short-text-padding": "Для использования многоколоночного режима (добавления широкого поля) следует увеличить ширину строки текста.",
    "books.margins.columns-count-info": "Для использования многоколоночного режима следует увеличить ширину строки текста.",
    "books.margins.wide-padding-info": "Для добавления широкого поля следует увеличить ширину строки текста.",
    "books.margins.columns-count-info-min-value": "Мин. значение:",
    "books.margins.columns-count-info-maket-value": "| В макете:",
    "books.margins.action-save-margins-state": "Сохрание полей",
    "books.error.all-margins-small-for-running-titles": "Минимальное поле для вертикального колонтитула – 20 мм.",
    "books.error.some-margins-small-for-running-titles": "Несколько полей меньше 15 мм, поэтому размещение в них колонтитулов не рекомендуется",
    "books.error.empty-margin": "Поле должно быть заполнено",
    "books.error.should-be-digits": "Не верное значение, должны быть только цифры",
    "books.error.should-be-gt-min-value": "Не верное значение, должно быть не менее",
    "books.error.should-be-lt-max-value": "Не верное значение, должно быть не более",
    "books.error.text-gt-margin-size": "Текст слишком длинный",
    "books.error.save-margins-state": "Ошибка при сохранении полей",
    "books.error.save-running-titles-state": "Ошибка при сохранении колонтитулов",
    "books.nav-tabs.margins": "Поля",
    "books.nav-tabs.running-titles": "Колонтитулы",
    "books.running-titles.hide-margins-borders": "Скрыть поля",
    "books.running-titles.show-margins-borders": "Показать поля",
    "books.running-titles.running-title": "Колонтитул",
    "books.running-titles.page-number": "Нумерация страниц",
    "books.running-titles.running-titles-design": "Дизайн колонтитулов",
    "books.running-titles.page-number-design": "Дизайн колонцифры",
    "books.running-titles.info": "Для настроек ваших полей возможны следующие варианты расположения колонтитула:",
    "books.running-titles.running-title-left": "Левый колонтитул",
    "books.running-titles.running-title-right": "Правый колонтитул",
    "books.running-titles.options.author": "Автор книги",
    "books.running-titles.options.book-name": "Название книги",
    "books.running-titles.options.chapters-name": "Названия глав",
    "books.running-titles.options.custom": "Другой",
    "books.running-titles.options.empty": "Пусто",
    "books.running-titles.chapter-name": "Название главы",
    "books.running-titles.action-save-running-titles-state": "Сохрание колонтитулов",
    "books.preview.process-info": "Мы сверстали первые страницы вашей книги. Если хотите внести изменения – используйте соответствующий пункт ниже.",
    "books.preview.process-info-2": "Для получения вёрстки всей книги нажмите кнопку «Сверстать всю книгу».",
    "books.preview.upload-file": "Загрузка файла",
    "books.preview.project-info": "Информация о проекте",
    "books.preview.templates": "Выбор шаблона",
    "books.preview.markup": "Разметка",
    "books.preview.margins": "Поля",
    "books.preview.running-titles": "Колонтитулы",
    "books.preview-page.edit.project-info": "Анкета проекта",
    "books.preview-page.edit.markup": "Разметка",
    "books.preview-page.edit.margins": "Макет",
    "books.preview-page.edit.tilte": "Превью вёрстки",
    "books.preview-page.edit.book-type.title": "Выберите версию(-и) издания",
    "books.preview-page.edit.export-print": "Печатная",
    "books.preview-page.edit.export-epub": "Электронная",
    "books.preview.preview-info-text": "Отображение электронной книги может меняться в зависимости от выбранной вами программы или устройства для чтения.<br>\nСкачайте превью по кнопке справа.",
    "books.preview.download-epub": "Скачать EPUB",
    "books.preview.preview-epub": "Предпросмотр EPUB",
    "books.preview.layout-book": "Сверстать книгу",
    "books.preview.hide-watermark": "Убрать водяной знак (2 т.)",
    "books.preview.payments-modal.header": "Для вёрстки всей книги вам нужно",
    "books.preview.payments-modal.your-balance": "У Вас есть:",
    "books.preview.payments-modal.use-gold": "Использовать",
    "books.preview.payments-modal.use-simple": "Подтвердить",
    "books.preview.payments-modal.what-is-gold-credits": "Что это?",
    "books.preview.payments-modal.what-is-gold-credits-text": "Супертокен – наша особая валюта, с ним можно сверстать любое издание, любого объема и содержания.",
    "books.preview.payments-modal.how-to-get-gold-credits": "Как получить?",
    "books.preview.payments-modal.how-to-get-gold-credits-text": "Каждые две недели мы проводим розыгрыши 3 супертокенов. Главное условие участия в розыгрышах: подписка на наши соцсети <a class=\"link\" href=\"https://vk.com/metranpageonline\" target=\"_blank\">Вк</a> и <a class=\"link\" href=\"https://t.me/gometranpage\" target=\"_blank\">Telegram</a>",
    "books.preview.payments-modal.vk": "Вк",
    "books.preview.payments-modal.and": "и",
    "books.preview.payments-modal.telegram": "Telegram",
    "books.preview.payments-modal.or": "или",
    "books.preview.payments-modal.not-enought": "Не хватает",
    "books.preview.payments-modal.buy-credits": "Докупить за ",
    "books.preview.payments-modal.get-gold-credits": "Как получить",
    "books.preview.payments-modal.not-enough-tokens": "Недостаточно токенов",
    "books.preview.payments-modal.header-01": "Стоимость вёрстки:",
    "books.preview.payments-modal.header-02": "В рамках текущей подписки закончился лимит токенов. Перейдите на тариф с большим количеством токенов, или обновите текущий.",
    "books.preview.payments-modal.use-gold-token-01": "Использовать супертокен",
    "books.error.select-type-before-finals": "Пожалуйста, выберите тип издания",
    "books.error.save-watermark-visible": "Ошибка сохранения метки",
    "books.preview.processing-steps": "Начался процесс вёрстки первых разворотов книги. ",
    "books.preview.processing-steps-2": "На этой странице вы можете следить за прогрессом.",
    "books.layout-steps.step-start": "Создание документа",
    "books.layout-steps.step-1": "Отрисовка шаблона",
    "books.layout-steps.step-2": "Создание стилей",
    "books.layout-steps.step-3": "Проверка текста",
    "books.layout-steps.step-4": "Обработка заголовков",
    "books.layout-steps.step-stop": "Cохранение результата",
    "books.book.download-project-button": "Скачать проект .zip",
    "books.margin.warning-multi-column-layout": "Для многоколоночной вёрстки следует увеличить ширину текстового поля",
    "books.margin.warning-wide-paddings-layout": "Для широких полей следует ",
    "books.margin.warning-wide-paddings-layout-2": "адаптивное положение изображений",
    "books.margin.warning-wide-paddings-layout-settings": "включить",
    "books.temp-ebook-not-work-yet": "Для вёрстки электронной книги все параметры относящиеся к полям и колонтитулам не требуют дополнительной настройки.",
    "books.markup.additional-settings-not-available-in-epub": "Эти настройки не применимы для электронной версии книги и будут использоваться только для печатной версии. ",
    "books.images-position-settings-modal.title": "Размещение изображений",
    "books.free-tariff-notification.most-settings-is-not-available": "В бесплатном тарифе вам недоступно<br>большинство настроек",
    "books.free-tariff-notification.most-settings-is-not-available-column": "В бесплатном тарифе вам<br>недоступно большинство настроек",
    "books.free-tariff-notification.trial-access-1": "Бесплатный",
    "books.free-tariff-notification.trial-access-2": "-дневный доступ",
    "books.free-tariff-notification.trial-expire": "Истекает",
    "books.free-tariff-notification.get-tariff": "Откройте все возможности,<br>оформив подписку",
    "books.new.project-modal.complete": "Готово",
    "books.new.project-modal.general.section": "Общие сведения",
    "books.new.project-modal.general.title": "Название",
    "books.new.project-modal.general.title-placeholder": "Название книги",
    "books.new.project-modal.general.author-last-name": "Фамилия автора",
    "books.new.project-modal.general.author-last-name-placeholder": "Фамилия автора",
    "books.new.project-modal.general.author-first-name": "Имя автора",
    "books.new.project-modal.general.author-first-name-placeholder": "Имя автора",
    "books.new.project-modal.general.author-middle-name": "Отчество автора",
    "books.new.project-modal.general.author-middle-name-placeholder": "Отчество автора",
    "books.new.project-modal.general.city": "Город",
    "books.new.project-modal.general.city-placeholder": "Город",
    "books.new.project-modal.general.year": "Год",
    "books.new.project-modal.general.year-placeholder": "Год",
    "books.new.project-modal.additional.section": "Выбор языка и версии",
    "books.new.project-modal.additional.language": "Язык издания",
    "books.new.project-modal.additional.age-limit": "Возрастная категория",
    "books.new.project-modal.additional.age-limits": "Возрастной рейтинг",
    "books.new.project-modal.additional.age-limits-0": "Контент для малышей до 6 лет может включать символические сцены насилия, если это соответствует жанру, с учетом сочувствия пострадавшему и акцентирования победы светлых сил над темными.",
    "books.new.project-modal.additional.age-limits-6": "Допустимы описания не реалистичных сцен болезней, аварий и насилия, но без изображения их последствий. ",
    "books.new.project-modal.additional.age-limits-12": "Можно изображать насилие, бедствия без подробностей. Алкоголь, табак и наркотики — только в осуждающем контексте. Простые описания объятий и поцелуев разрешены. ",
    "books.new.project-modal.additional.age-limits-16": "В книгах допустимы упоминания болезней, бедствий без деталей. Насилие и употребление вредных веществ осуждаются. Грубый язык - да, мат - нет. Сексуальные сцены без анатомических деталей. ",
    "books.new.project-modal.additional.age-limits-18": "Книга содержит подробные описания болезней, катастроф, употребления наркотиков и алкоголя, реалистичные интимные сцены, нетрадиционные отношения и нецензурную лексику.",
    "books.new.project-modal.additional.project-version": "Версия",
    "books.new.project-modal.additional.print-book": "Печатная",
    "books.new.project-modal.additional.e-book": "Электронная",
    "books.new.project-modal.additional.enable-edition-notice-page": "Включить в верстку",
    "books.new.project-modal.additional.edition-notice-page-enable-info": "Страница с выходными данными будет находиться после титула",
    "books.new.project-modal.additional.edition-notice-page-disable-info": "выкл",
    "books.new.project-modal.format.section": "Формат издания",
    "books.new.project-modal.format.height": "Высота, мм",
    "books.new.project-modal.format.width": "Ширина, мм",
    "books.new.project-modal.format.info": "Если в вашем издании есть таблицы, могут возникнуть сложности с вёрсткой. Рекомендуем выбрать формат, ширина которого будет не меньше 180 мм.",
    "books.new.project-modal.edition-notice.section": "Выходные данные",
    "books.new.project-modal.edition-notice.isbn": "ISBN",
    "books.new.project-modal.edition-notice.udk": "Код УДК",
    "books.new.project-modal.edition-notice.bbk": "Код ББК",
    "books.new.project-modal.edition-notice.annotation": "Аннотация к изданию",
    "books.new.project-modal.edition-notice.annotation-placeholder": "Текст",
    "books.new.project-modal.edition-notice.adviser": "Научный руководитель",
    "books.new.project-modal.edition-notice.adviser-placeholder": "Научный руководитель",
    "books.new.project-modal.edition-notice.reviewers": "Рецензенты",
    "books.new.project-modal.edition-notice.reviewers-placeholder": "Рецензенты",
    "book.language.ru": "Русский",
    "book.language.en": "Английский",
    "book.language.de": "Немецкий",
    "books.info-modal.projects-count-limit.title": "Пора обновиться на тариф ",
    "books.info-modal.projects-count-limit.text": "На тарифе ",
    "books.info-modal.projects-count-limit.text-2": "Вам ",
    "books.info-modal.projects-count-limit.text-3": "для редактирования. Улучшите вашу подписку, чтобы работать с ",
    "books.info-modal.projects-count-limit.text-4": "одновременно.",
    "books.info-modal.projects-count-limit.unlimited-projects-count": "неограниченным количеством проектов",
    "books.info-modal.projects-count-limit.pluralize-available": "{value, plural, zero {доступно} one {доступен} two {доступно} few {доступно} many {доступно} other {доступно} }",
    "books.info-modal.projects-count-limit.pluralize-projects-count": "{value, plural, zero {проектов} one {проект} two {проекта} few {проекта} many {проектов} other {проектов} }",
    "books.info-modal.projects-count-limit.pluralize-available-projects-count": "{value, plural, zero {проектов} one {проектом} two {проектами} few {проектами} many {проектами} other {проектами} }",
    "books.info-modal.projects-count-limit.action-text": "Подобрать тариф",
    "books.info-modal.file-size-limit.title": "Размер файла больше допустимого",
    "books.info-modal.file-size-limit.text": "На тарифе FREE максимальный размер загружаемого файла равен {$fileLimit} мб. Разблокируйте загрузку больших файлов, перейдя на тариф BEGINNER.",
    "books.info-modal.file-size-limit.action-text": "Подобрать тариф",
    "books.layout-as-a-gift-conditions.text": "Для этого необходимо соблюсти условия:<br> – Объём рукописи не более 80 тысяч символов<br> – Отсутствуют таблицы",
    "books.layout-as-a-gift-conditions": "Для этого необходимо соблюсти условия:",
    "books.info-modal.free-layout.title!": "Вёрстка в подарок!",
    "books.info-modal.free-layout.title": "Вёрстка в подарок",
    "books.info-modal.free-layout.text": "Объём вашей книги составил менее 80 тысяч символов. Это означает, что мы сверстаем её для вас бесплатно.",
    "books.info-modal.free-layout.action-text": "Спасибо",
    "books.epub.layout.error.epub-fixer-service-error": "Ошибка формирования файла epub",
    "books.format.unit": "мм",
    "books.template.create.cover": "Создать обложку",
    "books.template.create.cover.description": "Сгенерировать с помощью AI или загрузить своё изображение",
    "books.modal.header.upload-step": "Загрузка документа",
    "books.modal.header.book-data-step": "Анкета",
    "books.modal.upload-template.title": "Загрузить свой шаблон",
    "books.modal.upload-template.phone": "Номер телефона",
    "books.modal.upload-template.phone.placeholder": "Номер телефона",
    "books.modal.upload-template.email": "Почта",
    "books.modal.upload-template.email.placeholder": "Почта",
    "books.modal.upload-template.upload-button.text-1": "Перетащите сюда",
    "books.modal.upload-template.upload-button.text-2": "или",
    "books.modal.upload-template.upload-button.text-2-upload": "загрузите",
    "books.modal.upload-template.upload-button.text-3": "файл",
    "books.modal.upload-template.request.button": "Отправить запрос",
    "books.modal.upload-template.request.success": "Файл загружен. Менеджер скоро свяжется с Вами",
    "books.modal.upload-template.request.error": "Произошла ошибка при загрузке файла",
    "books.modal.upload-template.request.error.file-limit": "Размер файла слишком большой",
    "books.modal.upload-template.upload.process-hint": "Файл загружается",
    "books.modal.upload-template.upload.description": "Если вы создаете множество изданий с похожим оформлением, вы можете загрузить пример дизайна или файл indd. <br><br>Мы адаптируем его его для использования в редакторе, что значительно ускорит процесс верстки ваших будущих проектов.",
    "books.breadcrumb.book-data": "Анкета проекта",
    "books.breadcrumb.preview": "Превью и вëрстка",
    "cover.modal.create.title": "Новая обложка",
    "cover.modal.create.project-name.placeholder": "Название книги",
    "cover.modal.create.author-firstname.placeholder": "Имя",
    "cover.modal.create.author-lastname.placeholder": "Фамилия",
    "cover.modal.create.select-format": "Выберите формат",
    "cover.modal.create.custom-format": "Свой размер",
    "cover.modal.create.enter-size": "Введите размер",
    "cover.modal.create.enter-size.width": "Ширина",
    "cover.modal.create.enter-size.height": "Высота",
    "cover.modal.create.create-button": "Создать",
    "books.preview.update-preview.modal.title": "Запустить превью?",
    "books.preview.update-preview.modal.yes": "Да",
    "books.preview.update-preview.modal.no": "Нет",
    "pricing.currency": "₽",
    "pricing.breadcrumbs.tariffs-and-payments": "Тарифы и оплата",
    "pricing.breadcrumbs.payments": "Оплата",
    "pricing.nav-tabs.subscription": "Подписка на сервис",
    "pricing.nav-tabs.buying-credits": "Покупка токенов",
    "pricing.nav-tabs.payment-history": "История платежей",
    "pricing.header": "Тарифы",
    "pricing.books-available": "Доступно токенов",
    "pricing.item1.title": "1 токен",
    "pricing.item1.comment": "1 токен = одна вёрстка любого количества полос вашего издания",
    "pricing.item1.price": "990 р.",
    "pricing.item1.old-price": "990 р.",
    "pricing.item1.url": "https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=metranpag&InvId=0&Culture=ru&Encoding=utf-8&OutSum=990&SignatureValue=b744e5d7c3a1894a15b26a85325b6f7e",
    "pricing.item2.title": "10 токенов",
    "pricing.item2.comment": "1 токен = одна вёрстка любого количества полос вашего издания",
    "pricing.item2.price": "7 900 р.",
    "pricing.item2.old-price": "7 900 р.",
    "pricing.item2.url": "https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=metranpag&InvId=0&Culture=ru&Encoding=utf-8&OutSum=7900&SignatureValue=6c8e111361a9a54a91b709b0b1fc0a29",
    "pricing.item3.title": "30 токенов",
    "pricing.item3.comment": "1 токен = одна вёрстка любого количества полос вашего издания",
    "pricing.item3.price": "19 900 р.",
    "pricing.item3.old-price": "19 900 р.",
    "pricing.item3.url": "https://auth.robokassa.ru/Merchant/Index.aspx?MerchantLogin=metranpag&InvId=0&Culture=ru&Encoding=utf-8&OutSum=19900&SignatureValue=77bf477d3a2d0a7738ce298282b421a9",
    "pricing.buy-button": "Купить",
    "pricing.not-available-title": "",
    "pricing.not-available-desc": "",
    "pricing.email-note": "При оплате используйте свой почтовый ящик:",
    "pricing.calculator.heading": "Расчет стоимости вашей книги",
    "pricing.calculator.text-count": "Объем текста, тыс. знаков",
    "pricing.calculator.images-count": "Количество изображений",
    "pricing.calculator.tables-count": "Количество таблиц",
    "pricing.calculator.credits-count": "Количество токенов",
    "pricing.calculator.for-free": "Бесплатно",
    "pricing.calculator.calculate": "Рассчитать",
    "pricing.calculator.recalculate": "Пересчитать",
    "pricing.calculator.result.heading": "Ощутите выгоду автоматической вёрстки 🔥",
    "pricing.calculator.result.market-average": "Среднее по рынку",
    "pricing.calculator.result.price": "Стоимость",
    "pricing.calculator.result.currency": "₽",
    "pricing.calculator.result.time": "Время",
    "pricing.calculator.result.service-average": "В Метранпаже",
    "pricing.calculator.result.time-unit-hours": "ч.",
    "pricing.calculator.result.time-unit-minutes": "мин.",
    "pricing.calculator.result.pay": "Оплатить",
    "pricing.calculator.panel.calculate-book": "Посчитаем сколько токенов нужно для вёрстки вашей книги?",
    "pricing.calculator.panel.new-calculate-book": "Расчитаем другую книгу?",
    "pricing.calculator.panel.for-layout-need": "Для вёрстки вашей книги нужно ",
    "pricing.calculator.panel.pluralize-for-layout-need": "{credits, plural, zero {Для вёрстки вашей книги нужно} one {Для вёрстки вашей книги нужен} two {Для вёрстки вашей книги нужно} few {Для вёрстки вашей книги нужно} many {Для вёрстки вашей книги нужно} other {Для вёрстки вашей книги нужно} }",
    "pricing.calculator.panel.this-profit-1": "Это дешевле среднерыночной цены на",
    "pricing.calculator.panel.this-profit-2": "и быстрее на",
    "pricing.calculator.panel.show-calculation": "Показать расчёт",
    "pricing.calculator.panel.buy-in-addition": "докупить за",
    "pricing.calculator.panel.layout": "К вёрстке",
    "pricing.calculator.panel.not-enough": "Не хватает",
    "pricing.calculator.panel.use-gold-credit": "или используйте",
    "pricing.calculator.panel.gold-credit": "супертокен",
    "pricing.news.header": "мы меняем ценовую политику! 😎",
    "pricing.news.header.date": "1 марта 2023 года ",
    "pricing.news.body-1": "Количество ",
    "pricing.news.body-2": " за вёрстку будет зависить от количества текста, картинок и таблиц. ",
    "pricing.news.body.link": "токенов",
    "pricing.news.popup.heading-1": "Все токены, ",
    "pricing.news.popup.heading-2": "купленные до 1 марта 2023,",
    "pricing.news.popup.heading-3": " сохранятся и станут золотыми!",
    "pricing.news.popup.block-1-1": "С одним супертокеном",
    "pricing.news.popup.block-1-2": "можно сверстать книгу любого объёма.",
    "pricing.news.popup.block-2": "с 1 марта",
    "pricing.news.popup.block-3-1": "Стоимость ",
    "pricing.news.popup.block-3-2": " токена – 450 ₽",
    "pricing.news.popup.block-4-1": "Новый токен позволит сверстать текст объёмом",
    "pricing.news.popup.block-4-2": " 40 тыс. знаков*",
    "pricing.news.popup.block-4-3": "на любой формат и с любым оформлением.",
    "pricing.news.popup.block-4-4": "Изображения",
    "pricing.news.popup.block-4-5": "и",
    "pricing.news.popup.block-4-6": "таблицы",
    "pricing.news.popup.block-4-7": "пересчитываются в знаки из расчета:",
    "pricing.news.popup.block-5-1": " = 2000 знаков",
    "pricing.news.popup.block-5-2": " = 1000 знаков",
    "pricing.news.popup.block-6-1": "*40 тыс. знаков",
    "pricing.news.popup.block-6-2": " – объём 1 авторского листа, ≈25 стр. А5, или ≈13 стр. А4, набором 12 кегля шрифта.",
    "pricing.message.header": "Запаситесь текущими токенами и верстайте без ограничений по самым выгодным ценам!",
    "pricing.message.body-1": "Текущие токены позволяют сверстать книгу",
    "pricing.message.body-2": "любого объема и содержания.",
    "pricing.message.offer-until": "Только до",
    "pricing.message.offer-date": "1 марта!",
    "pricing.error.empty-field": "Поле должно быть заполнено",
    "pricing.error.should-be-digits": "Неверное значение, должны быть только цифры",
    "pricing.error.should-be-gt-zero": "Неверное значение, должно быть больше нуля",
    "pricing.error.should-be-eq-gt-zero": "Неверное значение, не может быть отрицательным",
    "pricing.credit-table.author-list": "а.л.,",
    "pricing.credit-table.to-profit": "Сэкономьте",
    "pricing.credit-table.pay": "Оплатить",
    "pricing.credit-table.any-credits-count": "Другое количество",
    "pricing.subscription-table.month": "месяц",
    "pricing.subscription-table.year": "год",
    "pricing.promocode-panel.enter-promocode": "Введите промокод",
    "pricing.promocode-panel.activate-promocode": "Активировать",
    "pricing.pluralize-credits": "{credits, plural, zero {токенов} one {токен} two {токена} few {токена} many {токенов} other {токенов} }",
    "pricing.pluralize-books": "{books, plural, zero {книг} one {книга} two {книги} few {книги} many {книг} other {книг} }",
    "pricing.pluralize-author-lists": "{value, plural, zero {авторских листов} one {авторский лист} two {авторских листа} few {авторских листа} many {авторских листов} other {авторских листов} }",
    "pricing.pluralize-symbols": "{symbols, plural, zero {знаков} one {знак} two {знака} few {знака} many {знаков} other {знаков} }",
    "pricing.pluralize-simple-credits": "{credits, plural, zero {обычных} one {обычный} two {обычных} few {обычных} many {обычных} other {обычных} }",
    "pricing.pluralize-gold-credits": "{credits, plural, zero {супертокенов} one {супертокен} two {супертокена} few {супертокенов} many {супертокенов} other {супертокенов} }",
    "pricing.pluralize-not-enought-credits": "{credits, plural, zero {токенов} one {токена} two {токенов} few {токенов} many {токенов} other {токенов} }",
    "pricing.pluralize-days": "{days, plural, zero {дней} one {день} two {дня} few {дня} many {дней} other {дней} }",
    "pricing.pluralize-weeks": "{weeks, plural, zero {недель} one {неделя} two {недели} few {недель} many {недель} other {недель} }",
    "pricing.pluralize-months": "{months, plural, zero {месяцев} one {месяц} two {месяца} few {месяца} many {месяцев} other {месяцев} }",
    "pricing.pluralize-years": "{years, plural, zero {лет} one {год} two {года} few {года} many {лет} other {лет} }",
    "pricing.pluralize-active-projects": "{value, plural, zero {активных проектов} one {активный проект} two {активных проекта} few {активных проекта} many {активных проектов} other {активных проектов} }",
    "pricing.info-panel.header": "Вам доступно:",
    "pricing.info-panel.and": "и",
    "pricing.info-panel.sub-header-1": "и",
    "pricing.info-panel.sub-header-2": "любого объема.",
    "pricing.info-panel.more-info": "Подробнее о токенах",
    "pricing.info-panel.body-1": "1 токен",
    "pricing.info-panel.body-2": " = вёрстка",
    "pricing.info-panel.body-3": "1 авторского листа (а.л.) = 40 000 знаков,",
    "pricing.info-panel.body-4": "изображения и таблицы пересчитываются в количество знаков из расчета:",
    "pricing.info-panel.images-to-symbols": " = 8 000 знаков",
    "pricing.info-panel.tables-to-symbols": " = 12 000 знаков",
    "pricing.info-panel.modal.header-1": "Стоимость",
    "pricing.info-panel.modal.header-2": "токена –",
    "pricing.info-panel.modal.body": "1 токен позволяет сверстать текст объёмом <br><b>40 тыс. знаков*</b> на любой формат и с любым оформлением. <br><br><b>Изображения</b> и <b>таблицы</b> пересчитываются в знаки из расчета:",
    "pricing.info-panel.modal.footer": "<b>*40 тыс. знаков</b> – объём 1 авторского листа, ≈25 стр. А5, или ≈13 стр. А4, набором 12 кегля шрифта.",
    "pricing.info-panel.modal.body-1": "1 токен позволяет сверстать текст объёмом",
    "pricing.info-panel.modal.body-2": "40 тыс. знаков*",
    "pricing.info-panel.modal.body-3": "на любой формат и с любым оформлением.",
    "pricing.info-panel.modal.body-4": "Изображения",
    "pricing.info-panel.modal.body-5": "и",
    "pricing.info-panel.modal.body-6": "таблицы",
    "pricing.info-panel.modal.body-7": "пересчитываются в знаки из расчета:",
    "pricing.info-panel.modal.body-8": "*40 тыс. знаков",
    "pricing.info-panel.modal.body-9": "– объём 1 авторского листа, ≈25 стр. А5, или ≈13 стр. А4, набором 12 кегля шрифта.",
    "pricing.tariff-table.buy-credits": "Токены",
    "pricing.tariff-table.subscription": "Подписка",
    "pricing.tariff-table.footnote-1": "* По прошествию периода неиспользуемые токены не сгорают. При оплате за месяц выбранный тариф будет продлеваться",
    "pricing.tariff-table.footnote-2": "автоматически в течение одного года. Вы можете отменить автоматические платежи в любое время до даты платежа.\n",
    "pricing.tariff-table.footnote-3": "** Купленные токены остаются у вас, пока вы их не истратите ",
    "pricing.tariff-table.thousand": "тыс.",
    "pricing.tariff-table.million": "млн",
    "payment.errors.cant-get-payment-url": "Не удалось сгенерировать ссылку для оплаты",
    "payment.errors.payment-url-blocked": "Похоже, браузер заблокировал открытие окна оплаты.  Проверьте уведомления браузера, и разрешите показ всплывающих окон",
    "payment.errors.insufficient-funds": "Недостаточно токенов",
    "pricing.pluralize-charge-off": "{credits, plural, zero {списано} one {списан} two {списано} few {списано} many {списано} other {списано} }",
    "pricing.credit-short": "токен",
    "pricing.tariffs.title": "Подписка на сервис",
    "pricing.tariffs.for-whom.start": "Для новичков",
    "pricing.tariffs.for-whom.pro": "Активным пользователям",
    "pricing.tariffs.for-whom.business": "Для команд",
    "pricing.tariffs.for-whom.free": "Для начинающих<br>пользователей",
    "pricing.tariffs.for-whom.beginner": "Для авторов<br>самиздата",
    "pricing.tariffs.for-whom.specialist": "Для книжных верстальщиков,<br>кто активно работает с изданиями",
    "pricing.tariffs.for-whom.master": "Для бизнеса, кто активно верстает<br>в крупных объемах",
    "pricing.pluralize-symbols.1": "{symbols, plural, zero {символов} one {символ} two {символа} few {символа} many {символов} other {символов} }",
    "pricing.pluralize-images.1": "{images, plural, zero {изображений} one {изображение} two {изображения} few {изображений} many {изображений} other {изображений} }",
    "pricing.tariffs.generation": "Генерация",
    "pricing.tariffs.features.ulimited-upload-size": "Безлимитный объем загружаемой рукописи",
    "pricing.tariffs.features.template-editing": "Редактирование шаблонов разметки",
    "pricing.tariffs.features.layout-images": "Верстка изображений",
    "pricing.tariffs.features.layout-tables": "Верстка таблиц",
    "pricing.tariffs.features.no-watermark": "Отсутствие водяного знака",
    "pricing.tariffs.features.autopublish-auto": "Авто-публикация сгенерированных изображений в сообщество",
    "pricing.tariffs.features.autopublish-manual": "Ручная публикация сгенерированных изображений в сообщество",
    "pricing.tariffs.features.output-pdf": "Готовый печатный файл (.pdf)",
    "pricing.tariffs.features.output-src-indd": "Исходники верстки в формате (.indd)",
    "pricing.tariffs.features.output-epub": "Готовая электронная версия (.ePub)",
    "pricing.tariffs.features.2": "Вёрстка издания объёмом до ",
    "pricing.tariffs.features.2-1": "или",
    "pricing.tariffs.features.2-2": "Включено",
    "pricing.tariffs.features.2-3": "токенов/мес.",
    "pricing.tariffs.features.2-4": "Это вёрстка изданий общим объемом до ",
    "pricing.tariffs.features.3.free": "Верстка изданий с текстом и изображениями",
    "pricing.tariffs.features.3": "Верстка изданий с текстом, изображениями и таблицами",
    "pricing.tariffs.features.4": "Неограниченное \r\nколичество проектов",
    "pricing.tariffs.features.5.free": "Шаблоны без возможности редактирования",
    "pricing.tariffs.features.5": "Любые шаблоны с возможностью их изменения",
    "pricing.tariffs.features.6": "Редактируемый файл, готовый к печати (.pdf, InDesign)",
    "pricing.tariffs.features.7": "Максимальный объем файла ",
    "pricing.tariffs.features.7.units": "МБ",
    "pricing.tariffs.features.7.business": "Без ограничений",
    "pricing.tariffs.features.8-1": "Генерация до",
    "pricing.tariffs.features.8-2": "обложек на рабочий проект, с возможностью редактирования",
    "pricing.tariffs.features.8-2.free": "обложек на рабочий проект, без возможности редактирования",
    "pricing.tariffs.features.9": "Стоимость 1 токена – ",
    "pricing.tariffs.start.features.1": "Подходит для пользователей, желающих изучить инструмент. ",
    "pricing.tariffs.start.features.1-1": "пробного периода с полным функциионалом сервиса",
    "pricing.tariffs.pro.features.1": "Отлично подходит для авторов, дизайнеров, людей, кто активно работает с книгами",
    "pricing.tariffs.business.features.1": "Рабочий инструмент, который оптимизирует процессы вашей компании",
    "pricing.tariffs.gift-time": "в подарок",
    "pricing.tariffs.period.month": "Месяц",
    "pricing.tariffs.period.half-year": "Полгода",
    "pricing.tariffs.period.year": "Год",
    "pricing.tariffs.economy": "Экономия ",
    "pricing.tariffs.tariff-card.buttons.start": "Начать",
    "pricing.tariffs.tariff-card.buttons.connect": "Подключить сейчас",
    "pricing.tariffs.tariff-card.buttons.bill": "Выставить счёт",
    "pricing.tariffs.tariff-card.buttons.upgrade": "Перейти на тариф",
    "pricing.tariffs.tariff-card.buttons.downgrade": "Перейти на тариф",
    "pricing.tariffs.tariff-card.buttons.refresh": "Пополнить токены",
    "pricing.tariffs.tariff-card.buttons.active": "Тариф активирован",
    "pricing.tariffs.tariff-card.more-about": "Подробнее о тарифе",
    "pricing.tariffs.tariff-active-card.tariff-title": "Ваш текущий тарифный план:",
    "pricing.tariffs.tariff-active-card.expire": "Истекает",
    "pricing.tariffs.tariff-active-card.settings-button": "Управлять тарифом",
    "pricing.tariffs.accrual-period.tokens-one-time": "Токены начисляются единоразово",
    "pricing.tariffs.accrual-period.payment-once-a-month": "Платёж раз в месяц",
    "pricing.tariffs.accrual-period.payment-once-a-half-year": "Платёж раз в полгода",
    "pricing.tariffs.accrual-period.payment-once-a-year": "Платёж раз в год",
    "pricing.success-page.title": "Благодарим!",
    "pricing.success-page.text": "Ваш платёж успешно прошёл.",
    "pricing.success-page.return": "Вернуться назад",
    "pricing.failed-page.title": "Ошибка платежа",
    "pricing.failed-page.text": "Попробуйте произвести платёж позднее",
    "pricing.failed-page.return": "Вернуться назад",
    "pricing.next-tariff-connected-page.title-1": "Тариф",
    "pricing.next-tariff-connected-page.title-2": "успешно активирован",
    "pricing.next-tariff-connected-page.text": "Переход произойдёт автоматически по истечении оплаченного периода",
    "pricing.next-tariff-connected-page.return": "Вернуться назад",
    "pricing.promocode.title": "Получите подарок от Rugram",
    "pricing.promocode.description": "Подписка на сервис или автоматическая вёрстка любого издания с вашим<br>исключительным дизайном и стилем по персональному промокоду",
    "pricing.promocode.enter-promocode": "Введите промокод",
    "pricing.promocode.activate-button": "Активировать",
    "pricing.promocode.activation.success": "Активирован промокод",
    "pricing.promocode.activation.confirm": "Отлично",
    "pricing.promocode.activation.error.already-used": "Промокод уже использован",
    "pricing.promocode.activation.error.expired": "Действие промокода закончено",
    "pricing.promocode.activation.error.not-found": "Промокод неверный",
    "pricing.promocode.activation.error.error-occurs": "Произошла ошибка, повторите позднее",
    "pricing.promocode.activation.error.error-active-subscription-has-higher-level": "Введенный промокод ухудшает Вашу текущую подписку",
    "pricing.promocode.discount.title": "Активирована",
    "pricing.promocode.discount.title-accent": "скидка",
    "pricing.promocode.discount.description": "На один платёж по любому тарифу до",
    "pricing.faq.title-1": "Для чего нужна подписка?",
    "pricing.faq.text-1": "Подписка открывает все возможности сервиса: корректировку шаблонов, полей, колонтитулов и всех текстовых стилей. Помимо этого подписка позволяет загружать файлы любого объёма, верстать издания с таблицами, иметь более одного активного проекта и получать токены дешевле.",
    "pricing.faq.title-2": "Сгорают ли неиспользованные токены?",
    "pricing.faq.text-2": "Нет. При оформлении ежемесячной подписки в вашем личном кабинете каждый месяц будет обновляться количество токенов на равное лимиту токенов вашей подписки. Например, если у вас тариф SPECIALIST и количество токенов в вашем кабинете к концу месяца будет равняться 100 токенам, то на момент автоматического продления подписки число токенов станет равным сумме тарифа SPECIALIST 3500 токенов и остатку 100 токенов  – 3600 токенов. <br>При переходе на тариф выше, все текущие токены добавляются к новому тарифу и действуют ровно тот период тарифного плана, который вы выберете.",
    "pricing.faq.title-3": "Как получить токен дешевле?",
    "pricing.faq.text-3": "Чем дороже вы покупаете тариф, тем дешевле для вас стоят токены.<br>В тарифе SPECIALIST – токен дешевле на 6%<br>В тарифе MASTER – токен дешевле на 17%<br>чем в тарифе BEGINNER.",
    "pricing.faq.title-4": "Как отменить подписку?",
    "pricing.faq.text-4": "Просто перейдите на бесплатный тариф. Отключение подписки в таком случае произойдёт автоматически по истечении оплаченного периода, а не в день перехода.",
    "pricing.faq.title-5": "Что делать если закончились токены из подписки?",
    "pricing.faq.text-5": "Вы можете обновить текущий тариф. В таком случае баланс токенов обновится до максимального лимита вашей подписки и будет действовать весь месяц с момента активации.<br>Или вы сможете перейти на тариф выше.",
    "pricing.max-count-symbols-in-tariff-1.1": "До",
    "pricing.max-count-symbols-in-tariff-1.2": "символов",
    "pricing.max-count-images-in-tariff-1.1": "Генерация",
    "pricing.max-count-images-in-tariff-1.2": "изображений",
    "pricing.balance": "У вас есть:",
    "premium-tariffs-modal.title": "Откройте все возможности ",
    "premium-tariffs-modal.premium.title": "Премиум",
    "premium-tariffs-modal.description": "Оформите подписку, чтобы открыть премиальный функционал. И возможность создания нескольких проектов.",
    "editor.placeholder": "Начинайте вводить текст…",
    "editor.plugins.placeholder.heading_0": "Введите текст части",
    "editor.plugins.placeholder.heading_1": "Введите текст раздела",
    "editor.plugins.placeholder.heading_2": "Введите текст главы",
    "editor.plugins.placeholder.heading_3": "Введите текст подглавка",
    "editor.plugins.placeholder.heading_4": "Введите текст подзаголовка",
    "editor.plugins.placeholder.lead": "Введите текст врезки",
    "editor.plugins.placeholder.annotation": "Введите текст примечания",
    "editor.plugins.placeholder.photo_sign": "Введите текст подписи к фото",
    "editor.plugins.placeholder.quote": "Введите текст цитаты",
    "editor.plugins.placeholder.quote_author": "Введите текст подписи/даты",
    "editor.plugins.placeholder.verse": "Введите текст стиха",
    "editor.plugins.placeholder.footnotes": "Введите текст сноски",
    "editor.plugins.heading_0": "Часть",
    "editor.plugins.heading_1": "Раздел",
    "editor.plugins.heading_2": "Глава",
    "editor.plugins.heading_3": "Подглавка",
    "editor.plugins.heading_4": "Подзаголовок",
    "editor.plugins.lead": "Врезка",
    "editor.plugins.annotation": "Примечание",
    "editor.plugins.photo_sign": "Подпись к фото",
    "editor.plugins.quote": "Цитата",
    "editor.plugins.quote_author": "Подпись/Дата",
    "editor.plugins.verse": "Cтих",
    "editor.plugins.image": "Изображение",
    "editor.image.upload_hint_p1": "Перетащите сюда или",
    "editor.image.upload_hint_p2": "загрузите",
    "editor.image.upload_hint_p3": "файл изображения",
    "editor.image.upload_error": "Не удалось загрузить изображение",
    "editor.image.not_an_image_error": "Загруженный файл не является изображением",
    "editor.image.file-extension-error": "Файл данного типа не подходит",
    "editor.errors.toc": "Пожалуйста, удалите содержание",
    "editor.errors.table": "Пожалуйста, удалите таблицу",
    "editor.errors.table-cell-length": "Мы нашли ячейки таблицы с большим количеством символов. Для корректной верстки таблиц, мы рекомендуем держать текст в ячейках в границах 1000 символов",
    "editor.errors.action_delete": "удалить",
    "editor.table.context_menu.add": "Вставить",
    "editor.table.context_menu.add_rows_above": "{rows, plural, zero {} one {Вставить {rows} строку выше} few {Вставить {rows} строки выше} many {Вставить {rows} строк выше} other {Вставить {rows} строк выше} }",
    "editor.table.context_menu.add_rows_below": "{rows, plural, zero {} one {Вставить {rows} строку ниже} few {Вставить {rows} строки ниже} many {Вставить {rows} строк ниже} other {Вставить {rows} строк ниже} }",
    "editor.table.context_menu.add_columns_right": "{cols, plural, zero {} one {Вставить {cols} столбец справа} few {Вставить {cols} столбца справа} many {Вставить {cols} столбцов справа} other {Вставить {cols} столбцов справа} }",
    "editor.table.context_menu.add_columns_left": "{cols, plural, zero {} one {Вставить {cols} столбец слева} few {Вставить {cols} столбца слева} many {Вставить {cols} столбцов слева} other {Вставить {cols} столбцов слева} }",
    "editor.table.context_menu.delete": "Удалить",
    "editor.table.context_menu.delete_rows": "{rows, plural, zero {} one {Удалить {rows} строку} few {Удалить {rows} строки} many {Удалить {rows} строк} other {Удалить {rows} строк} }",
    "editor.table.context_menu.delete_columns": "{cols, plural, zero {} one {Удалить {cols} столбец} few {Удалить {cols} столбца} many {Удалить {cols} столбцов} other {Удалить {cols} столбцов} }",
    "editor.table.context_menu.delete_table": "table",
    "editor.table.context_menu.union_cells": "Объединить ячейки",
    "editor.table.toolbar.add_row": "Вставить строку ниже",
    "editor.table.toolbar.add_column": "Вставить столбец справа",
    "editor.image.select_size": "размер в вёрстке:",
    "editor.image.size_small": "мелкий",
    "editor.image.size_normal": "средний",
    "editor.image.size_large": "крупный",
    "editor.image.select_crop": "кадрирование",
    "editor.select.list_style": "выбрать стиль списка",
    "editor.image.size_fullpage": "во всю страницу",
    "editor.image.allow_crop": "обрезка изображения",
    "editor.image.allow_crop_hint": "При необходимости сервис обрежет изображение в вёрстке. В исходных файлах вёрстки (.indd) ваши изображения останутся без изменений, кадрирование можно будет поправить.",
    "editor.starts-on.next-page": "С новой страницы",
    "editor.starts-on.odd-page": "С нечетной страницы",
    "editor.starts-on.even-page": "С четной страницы",
    "editor.block.warning.posible-incorrect-union-blocks": "Возможно некорректное объединение",
    "editor.block.warning.check-list": "Проверьте список",
    "editor.block.warning.check-symbols": "Проверьте символы",
    "editor.block.warning.single-symbol": "Один символ",
    "editor.block.warning.undefined-symbols": "Нераспознанные символы",
    "editor.block.warning.starts-with-sup": "Начинается с superscript",
    "editor.block.warning.starts-with-sub": "Начинается с subscript",
    "editor.block.warning.dublicate-text": "Повторяющийся текст",
    "editor.block.warning.no-spaces": "Нет пробелов",
    "editor.block.warning.many-spaces": "Много пробелов",
    "editor.block.warning.containes-private-char": "Содержит неправильный символ",
    "editor.context-menu.ai.image-generation": "Сгенерировать изображение",
    "editor.context-menu.ai.upgrade-text": "Улучшить текст",
    "editor.context-menu.ai.check-grammar": "Проверить грамматику",
    "editor.context-menu.ai.increase-text": "Удлинить текст",
    "editor.context-menu.ai.decrease-text": "Укоротить текст",
    "editor.context-menu.ai.tone": "Изменить тон текста",
    "editor.context-menu.ai.tone.professional": "Профессиональный",
    "editor.context-menu.ai.tone.normal": "Обычный",
    "editor.context-menu.ai.tone.straightforward": "Прямолинейный",
    "editor.context-menu.ai.tone.confident": "Уверенный",
    "editor.context-menu.ai.tone.friendly": "Дружелюбный",
    "editor.context-menu.ai.what-do-you-want-placeholder": "Напишите, что вы хотите сделать с полученным текстом",
    "editor.context-menu.ai.apply-to-selection": "Применить на выделенное",
    "editor.context-menu.ai.paste-below": "Вставить ниже",
    "editor.context-menu.ai.continue-generating": "Продолжить генерировать",
    "editor.context-menu.ai.lengthen-current": "Удлинить текущее",
    "editor.context-menu.ai.shorten-current": "Укоротить текст",
    "editor.context-menu.ai.regenerate": "Перегенерировать",
    "editor.context-menu.ai.cancel-1": "Отменить",
    "editor.context-menu.ai.delete-generation-confirm": "Вы точно хотите удалить текущую генерацию?",
    "editor.context-menu.ai.delete-generation": "Удалить генерацию",
    "editor.context-menu.ai.cancel-2": "Отмена",
    "admin.nav.users": "Пользователи",
    "admin.nav.feature-flags": "Feature Flags",
    "admin.nav.public-templates": "Общие шаблоны",
    "admin.nav.templates": "Шаблоны",
    "admin.nav.non-editable-templates": "Нередактируемые шаблоны",
    "admin.nav.company": "Компания",
    "admin.nav.companies": "Компании",
    "admin.nav.tariffs": "Тарифы",
    "admin.nav.promocodes": "Промокоды",
    "admin.nav.referal-links": "Реферальные ссылки",
    "admin.nav.fonts": "Шрифты",
    "admin.nav.api": "API",
    "admin.nav.s3": "Настройки S3",
    "admin.nav.payments": "Настройки оплаты",
    "admin.nav.print-settings": "Настройки печати",
    "admin.nav.unisender": "Unisender",
    "admin.nav.image-generation-price": "Стоимость генерации изображений",
    "admin.nav.image-generation-watermark": "Настройка водяного знака на изображениях",
    "admin.nav.edition-notice-settings": "Выходные данные",
    "admin.nav.moderation.images": "Модерация изображений",
    "admin.nav.moderation.covers": "Модерация обложек",
    "admin.nav.telegram-settings": "Настройки Telegram",
    "admin.nav.formats": "Форматы",
    "admin.nav.rewards-onetime": "Разовые награды",
    "admin.nav.rewards-daily": "Ежедневные награды",
    "admin.nav.social-network-covers": "Обложки социальных сетей",
    "admin.nav.maintenance-settings": "Обслуживание",
    "admin.nav.cover-snippets": "Обложки Сниппеты",
    "admin.common.create": "Создать",
    "admin.common.delete": "Удалить",
    "admin.common.save": "Сохранить",
    "admin.common.hide": "Скрыть",
    "admin.common.cancel": "Отмена",
    "admin.common.select.all": "Все",
    "admin.common.copy-text": "Скопировать текст",
    "admin.common.details": "Подробнее",
    "admin.common.edit": "Редактировать",
    "admin.templates.add-template": "Добавить шаблон",
    "admin.templates.template.title": "Название",
    "admin.templates.template.description-short": "Описание (короткое)",
    "admin.templates.template.description-long": "Описание",
    "admin.templates.template.description-long-tags-description": "<b>TEXT</b> - жирный, <i>TEXT</i> - курсив",
    "admin.templates.template.description-long-preview": "Превью",
    "admin.templates.template.available-to-user": "Доступно пользователям",
    "admin.templates.template.available-to-companies": "Доступно другим компаниям",
    "admin.templates.template.is-premium": "Премиальный шаблон",
    "admin.templates.template.section-sizes": "Размеры",
    "admin.templates.template.width-from": "Ширина от, мм",
    "admin.templates.template.width-to": "Ширина до, мм",
    "admin.templates.template.height-from": "Высота от, мм",
    "admin.templates.template.height-to": "Высота до, мм",
    "admin.templates.template.error.cant-add-template": "Не удалось добавить шаблон",
    "admin.templates.template.error.cant-delete-template": "Не удалось удалить шаблон",
    "admin.templates.template.error.cant-update-template": "Не удалось обновить шаблон",
    "admin.templates.template.error.cant-copy-template": "Не удалось скопировать шаблон",
    "admin.templates.template.error.cant-upload-preview": "Не удалось загрузить превью",
    "admin.templates.template.error.cant-update-order": "Не удалось обновить порядок",
    "admin.templates.template.hide-template-confirmation": "Скрыть шаблон?",
    "admin.templates.template.section-styles": "Стили",
    "admin.templates.template.font-main": "Шрифт основного текста",
    "admin.templates.template.font-heading": "Шрифт заголовков",
    "admin.templates.template.main-text-size": "Размер основного текста",
    "admin.templates.template.section-colors": "Цвета",
    "admin.templates.template.color-accent": "Цвет акцент",
    "admin.templates.template.color-primary": "Цвет основной",
    "admin.templates.template.color-secondary": "Цвет дополнительный",
    "admin.templates.template.color-decor": "Цвет декор",
    "admin.templates.template.section-layout": "Макет",
    "admin.templates.template.center-images": "Изображения по центру",
    "admin.templates.template.margin-top": "Верх",
    "admin.templates.template.margin-bottom": "Низ",
    "admin.templates.template.margin-inner": "Внутренее",
    "admin.templates.template.margin-outer": "Внешнее",
    "admin.templates.template.gutter": "Межколоночный интервал",
    "admin.templates.template.columns-count": "Количество колонок",
    "admin.templates.template.wide-padding": "Широкое поле",
    "admin.templates.template.wide-padding-left-pos": "Левая страница",
    "admin.templates.template.wide-padding-right-pos": "Правая страница",
    "admin.templates.template.wide-padding-size": "Размер широкого поля",
    "admin.templates.template.section-running-title": "Колонтитул",
    "admin.templates.template.rt-state": "Колонтитул вкл/выкл",
    "admin.templates.template.rt-left": "Левая страница",
    "admin.templates.template.rt-right": "Правая страница",
    "admin.templates.template.rt-position": "Позиция",
    "admin.templates.template.rt-style": "Стиль",
    "admin.templates.template.pn-state": "Колонцифра вкл/выкл",
    "admin.templates.template.pn-position": "Позиция",
    "admin.templates.template.pn-style": "Стиль",
    "admin.template.add-style": "Добавить стиль",
    "admin.templates.template.styles.localization": "Локализация",
    "admin.templates.template.styles-title": "Стили текста",
    "admin.templates.template.styles.font": "Шрифт ",
    "admin.templates.template.styles.font-size": "Размер текста",
    "admin.templates.template.styles.leading": "Межстрочный интервал",
    "admin.templates.template.styles.tracking": "Межсимвольный интервал",
    "admin.templates.template.styles.emphasis": "Начертание",
    "admin.templates.template.styles.align-maintext": "Выравнивание основного текста",
    "admin.templates.template.styles.align": "Выравнивание",
    "admin.templates.template.styles.case": "Регистр",
    "admin.templates.template.styles.one-on-page": "Один на странице",
    "admin.templates.template.styles.start-on": "Начинать с",
    "admin.templates.template.styles.drop-cap": "Буквица",
    "admin.templates.template.styles.drop-cap-chars": "Количество символов буквицы",
    "admin.templates.template.styles.drop-cap-lines": "Количество строк буквицы",
    "admin.templates.template.styles.indent-paragraph": "Отбивка абзацев",
    "admin.templates.template.styles.indent-paragraph-after-header": "Красная строка после заголовков",
    "admin.templates.template.styles.indent-header": "Отбивка заголовков",
    "admin.templates.template.styles.type": "Тип стиля",
    "admin.templates.template.toc": "Содержание",
    "admin.templates.template.toc-visible": "Добавить содержание",
    "admin.templates.template.toc-position": "Расположение содержания",
    "admin.templates.template.company": "Компания",
    "admin.templates.template.editable-settings": "Настройки редактируемости шаблона",
    "admin.templates.template.is-editable": "Редактируемый",
    "admin.templates.template.non-editable-template-name": "Название нередактируемого шаблона",
    "admin.templates.template.images-settings-available": "Разрешить настройку изображений",
    "admin.templates.template.images-size": "Размер изображения",
    "admin.templates.template.images-crop-class": "Кадрирование изображений",
    "admin.templates.template.styles.is-displayed-at-sidebar": "Показывать в сайдбаре",
    "admin.templates.template.styles.is-configurable-at-details-sidebar": "Показывать в сайдбаре детальных настроек",
    "admin.non-editable-templates.non-editable-template.add-non-editable-template": "Добавить шаблон",
    "admin.non-editable-templates.non-editable-template.download-non-editable-template-example": "Скачать пример шаблона",
    "admin.non-editable-templates.non-editable-template.delete-non-editable-template": "Удалить",
    "admin.non-editable-templates.non-editable-template.error.cant-add-non-editable-template": "Не удалось добавить нередактируемый  шаблон",
    "admin.non-editable-templates.non-editable-template.error.cant-delete-non-editable-template": "Не удалось удалить нередактируемый  шаблон",
    "admin.non-editable-templates.non-editable-template.error.cant-update-non-editable-template": "Не удалось обновить нередактируемый  шаблон",
    "admin.non-editable-templates.non-editable-template.error.cant-copy-non-editable-template": "Не удалось скопировать нередактируемый шаблон",
    "admin.non-editable-templates.non-editable-template.error.cant-upload-archive": "Не удалось загрузить архив",
    "admin.non-editable-templates.non-editable-template.error.title-must-be-unique": "Название шаблона должно быть уникальным",
    "admin.non-editable-templates.non-editable-template.error.template-file-not-found": "Файл шаблона не найден",
    "admin.non-editable-templates.non-editable-template.error.settings-file-not-found": "Файл настроек settings.json не найден",
    "admin.companies.add-company": "Добавить компанию",
    "admin.companies.edit-company": "Редактировать компанию",
    "admin.companies.change-company-data": "Изменить данные компании?",
    "admin.companies.company.title": "Название",
    "admin.companies.company.landing-url": "Адрес лэндинга",
    "admin.companies.company.host-url": "Адрес сайта",
    "admin.companies.company.knowledge-base-url": "URL базы знаний",
    "admin.companies.company.receipt-template-for-subscriptions": "Шаблон для чеков (подписки)",
    "admin.companies.company.unisender-templates": "Шаблоны писем Unisender",
    "admin.companies.company.unisender-go-templates": "Шаблоны писем Unisender Go",
    "admin.companies.company.unisender-template-language.ru": "Русский",
    "admin.companies.company.unisender-template-language.en": "Английский",
    "admin.companies.company.unisender-go-template-id.verification-email": "Подтверждение почты",
    "admin.companies.company.unisender-go-template-id.restore-password": "Восстановление пароля",
    "admin.companies.company.unisender-go-template-id.subscribtion-ending-notice": "Уведомление о конце подписки",
    "admin.companies.company.unisender-go-template-id.fail-payment-notice": "Уведомление о неудачном платеже",
    "admin.companies.company.unisender-go-template-id.force-free-tariff-notice": "Уведомление о переводе на бесплатный тариф",
    "admin.companies.company.unisender-go-template-id.trial-end-notice": "Уведомление о конце триала",
    "admin.companies.company.unisender-template-settings.title": "Название",
    "admin.companies.company.unisender-template-settings.list-id": "Id списка",
    "admin.companies.company.unisender-template-settings.timeout": "Задержка",
    "admin.companies.company.can-produce-epub": "Верстка ePub",
    "admin.companies.company.is-logout-button-available": "Отображать кнопку «Выйти»",
    "admin.companies.company.welcome-credits-count": "Количество приветственных токенов",
    "admin.companies.company.error.cant-create-company": "Не удалось создать компанию",
    "admin.companies.company.error.cant-delete-company": "Не удалось удалить компанию",
    "admin.companies.company.delete-company-confirmation": "Удалить компанию",
    "admin.tariffs.add-tariff": "Добавить тариф",
    "admin.tariffs.tariff.title": "Название",
    "admin.tariffs.tariff.subtitle": "Для кого",
    "admin.tariffs.tariff.company-title": "Компания",
    "admin.tariffs.tariff.price": "Цена",
    "admin.tariffs.tariff.profit": "Выгода",
    "admin.tariffs.tariff.currency": "Валюта ",
    "admin.tariffs.tariff.period": "Период",
    "admin.tariffs.tariff.credits-per-month": "Количество токенов в месяц",
    "admin.tariffs.tariff.gold-credits-per-month": "Количество супертокенов в месяц",
    "admin.tariffs.tariff.credits-helper": "Будет начислено при активации тарифа:",
    "admin.tariffs.tariff.active-projects-count": "Количество активных проектов",
    "admin.tariffs.tariff.is-free": "Бесплатный тариф",
    "admin.tariffs.tariff.gift-period": "Месяцев в подарок",
    "admin.tariffs.tariff.trial-period": "Пробный период",
    "admin.tariffs.tariff.legal-entity": "Для юридических лиц",
    "admin.tariffs.tariff.file-volume-limit": "Максимальный объем файла",
    "admin.tariffs.tariff.level": "Уровень тарифа (от 1 и выше)",
    "admin.tariffs.tariff.credit-price": "Стоимость кредита внутри подписки",
    "admin.tariffs.tariff.is-unlimited": "Безлимитный",
    "admin.tariffs.tariff.error.cant-update-tariff": "Не удалось обновить тариф",
    "admin.tariffs.tariff.error.cant-create-tariff": "Не удалось создать тариф",
    "admin.tariffs.tariff.error.cant-delete-tariff": "Не удалось удалить тариф",
    "admin.tariffs.tariff.delete-tariff-confirmation": "Удалить тариф",
    "admin.tariffs.tariff.error.cant-copy-tariff": "Не удалось скопировать тариф",
    "admin.companies.company.image-generation-price.basic": "Базовая генерация",
    "admin.companies.company.image-generation-price.advanced": "Расширенная генерация",
    "admin.companies.company.image-generation-price.advanced.fast": "Расширенная генерация (быстрый режим)",
    "admin.companies.company.image-generation-price.advanced.quality": "Расширенная генерация (качественный режим)",
    "admin.companies.company.image-generation-price.advanced.ultra": "Расширенная генерация (ультра режим)",
    "admin.companies.company.image-generation-price.advanced.fabula": "Расширенная генерация Face Swap (Fabula AI)",
    "admin.companies.company.image-generation-price.creative": "Креативная генерация",
    "admin.companies.company.image-generation-price.other": "Другие",
    "admin.companies.company.image-generation-price.1": "Стоимость генерации 1 изображения (генерация 1 изображений), токены",
    "admin.companies.company.image-generation-price.2": "Стоимость генерации 1 изображения (генерация 2 изображений), токены",
    "admin.companies.company.image-generation-price.3": "Стоимость генерации 1 изображения (генерация 3 изображений), токены",
    "admin.companies.company.image-generation-price.4": "Стоимость генерации 1 изображения (генерация 4 изображений), токены",
    "admin.companies.company.image-generation-price.unzoom": "Стоимость unzoom 1 изображения, токены",
    "admin.companies.company.image-generation-price.upscale": "Стоимость upscale 1 изображения, токены",
    "admin.companies.company.image-generation-price.nobg": "Стоимость удаления фона с 1 изображения, токены",
    "admin.companies.company.image-generation-price.coefficient-for-generation-with-elements": "Коэффициент для генерации 1 изображения с элементом",
    "admin.companies.company.image-generation-price.coefficient-for-convert-leonardo-tokens": "Коэффициент конвертации токенов Leonardo",
    "admin.companies.company.image-generation-price.fabula-remove-background": "Стоимость удаления фона (Fabula), токены",
    "admin.companies.company.image-generation-price.fabula-character-reference-coefficient": "Повтор персонажа, коэффициент",
    "admin.companies.company.image-generation-price.creative-character-reference-coefficient": "Повтор персонажа, коэффициент",
    "admin.companies.company.image-generation-watermark.title": "Настройка водяного знака на сгенерированных изображениях ",
    "admin.companies.company.image-generation-watermark.company-logo-image": "Логотип компании",
    "admin.companies.company.image-generation-watermark.is-visible": "Водяной знак виден",
    "admin.companies.company.image-generation-watermark.offset-bottom": "Смещение снизу от нижнего правого угла",
    "admin.companies.company.image-generation-watermark.offset-right": "Смещение справа от нижнего правого угла",
    "admin.companies.company.image-generation-watermark-settings.no-image-file.error": "Нет файла изображения",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-1": "Перетащите сюда",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-2": "или",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-2-upload": "загрузите",
    "admin.companies.company.image-generation-watermark-settings.upload-button.text-3": "файл",
    "admin.companies.company.image-generation-watermark-settings.upload-another-button.text-1-upload": "Загрузить",
    "admin.companies.company.image-generation-watermark-settings.upload-another-button.text-2": "другой файл",
    "admin.currencies.ruouble": "Рубль",
    "admin.currencies.dollar-us": "Доллар",
    "admin.currencies.euro": "Евро",
    "admin.currencies.pound-sterling": "Фунт стерлингов",
    "admin.promocodes.add-promocode": "Добавить промокод",
    "admin.promocodes.promocode.delete-promocode-confirmation": "Удалить промокод",
    "admin.promocodes.promocode.error.cant-create-promocode": "Ошибка создания промокода",
    "admin.promocodes.promocode.error.cant-delete-promocode": "Ошибка удаления промокода",
    "admin.promocodes.promocode.token": "Токен",
    "admin.promocodes.promocode.status": "Статус",
    "admin.promocodes.promocode.company": "Компания",
    "admin.promocodes.promocode.agent": "Агент",
    "admin.promocodes.promocode.created-date": "Дата начала",
    "admin.promocodes.promocode.expire-date": "Дата завершения",
    "admin.promocodes.promocode.single-use-or-multiple": "Одноразовый или многоразовый",
    "admin.promocodes.promocode.single-use": "Одноразовый (может использоваться одним пользователем)",
    "admin.promocodes.promocode.description": "Описание после успешной активации",
    "admin.promocodes.promocode.credits-count": "Количество токенов",
    "admin.promocodes.promocode.gold-credits-count": "Количество супертокенов",
    "admin.promocodes.promocode.tariff-id": "Номер ID тарифа",
    "admin.promocodes.promocode.subscription-period": "Период подписки",
    "admin.promocodes.promocode.discont-for-subscription": "Скидка на подписку, %",
    "admin.promocodes.promocode.error.cant-update-promocode": "Ошибка обновления промокода",
    "admin.promocodes.promocode.select-company": "Выберите компанию",
    "admin.promocodes.promocode.select-tariff": "Выберите тариф",
    "admin.promocodes.promocode.error.promocode-token-must-be-unique": "Промокод должен быть уникальным ",
    "admin.promocodes.promocode-type.tokens": "Токены",
    "admin.promocodes.promocode-type.discount": "Скидка",
    "admin.promocodes.promocode-type.subscription": "Подписка",
    "admin.referal-links.add-referal-link": "Добавить реферальную ссылку",
    "admin.referal-links.referal-link.delete-referal-link-confirmation": "Удалить реферальную ссылку",
    "admin.referal-links.referal-link.error.cant-create-referal-link": "Ошибка создания реферальной ссылки",
    "admin.referal-links.referal-link.error.cant-delete-referal-link": "Ошибка удаления реферальной ссылки",
    "admin.referal-links.referal-link.token": "Токен",
    "admin.referal-links.referal-link.company": "Компания",
    "admin.referal-links.referal-link.agent-fee": "Агентский %",
    "admin.referal-links.referal-link.error.cant-update-referal-link": "Ошибка обновления реферальной ссылки",
    "admin.referal-links.referal-link.error.referal-link-token-must-be-unique": "Реферальная ссылка должна быть уникальной ",
    "admin.users.add-subscription.error.active-tariff-is-higher": "Текущий тариф выше",
    "admin.users.add-subscription.error.active-subscription-has-next-tariff": "У текущей подписки есть следующий тариф",
    "admin.fonts.add-font": "Добавить шрифт",
    "admin.fonts.font.delete-font-confirmation": "Удалить шрифт",
    "admin.fonts.font.error.cant-create-font": "Ошибка создания шрифта",
    "admin.fonts.font.error.cant-delete-font": "Ошибка удаления шрифта",
    "admin.fonts.font.name": "Название шрифта",
    "admin.fonts.font.family-name": "Семейство",
    "admin.fonts.font.subfamily-name": "Подсемейство",
    "admin.fonts.font.file": "Файл шрифта",
    "admin.fonts.font.license": "Лицензия",
    "admin.fonts.font.license-placeholder": "Лицензия",
    "admin.fonts.font.is-available-for-headers": "Доступен для заголовков",
    "admin.fonts.font.is-available-for-main-text": "Доступен для основного текста",
    "admin.fonts.font.is-selectable": "Доступен для выбора",
    "admin.fonts.font.error.cant-update-font": "Ошибка обновления шрифта",
    "admin.fonts.font.error.font-name-must-be-unique": "Название шрифта должно быть уникальным ",
    "admin.fonts.upload-help": "Загружать шрифты можно по одному файлу и указывать текст лицензии\n<br>Можно загрузить архив .zip со шрифтами, и файлом license.json в корне архива, в котором указан текст лицензии для каждой локали \n<br>{\n<br>ru: “текст лицензия на русском языке“,\n<br>en: “текст лицензия на английском языке“\n<br>}\n<br>При отсутствии файла license.json значение лицензий примениться из файла шрифта ",
    "admin.api": "API",
    "admin.api.available-token": "API токен",
    "admin.api.reset-token": "Сбросить токен",
    "admin.api.link-to-doc": "Подробнее почитать про интеграцию и API можно по ссылке:",
    "admin.api.webhook-url": "Webhook URL",
    "admin.api.test-webhook": "Отправить тестовый запрос",
    "admin.api.webhook-result": "Результат запроса",
    "admin.api.redirect-on-layout-finish-url": "URL перенаправления после окончания верстки",
    "admin.company.currency": "Валюта",
    "admin.cloudpayments.enable": "Оплата через CloudPayments",
    "admin.cloudpayments.public-id": "PublicId",
    "admin.cloudpayments.private-key": "PrivateKey",
    "admin.cloudpayments.description": "Вы можете использовать свою кассу, при приеме платежей в Метранпаже. Для этого нужно завести учетную запись <a href=\"https://developers.cloudpayments.ru/#obschaya-informatsiya\">Cloud Payments</a>",
    "admin.stripe.enable": "Оплата через Stripe",
    "admin.stripe.public-key": "PublicKey",
    "admin.stripe.secret-key": "SecretKey",
    "admin.stripe.webhook-secret": "WebhookSecret",
    "admin.stripe.description": "Вы можете использовать свою кассу, при приеме платежей в Метранпаже. Для этого нужно завести учетную запись <a href=\"https://docs.stripe.com/sdks\">Stripe</a>",
    "admin.s3.endpoint": "Endpoint",
    "admin.s3.region": "Region",
    "admin.s3.bucket": "Bucket",
    "admin.s3.access-key": "AccessKey",
    "admin.s3.secret-key": "SecretKey",
    "admin.unisender.unisender-api-key": "Unisender Api Key",
    "admin.unisender.unisender-go-api-key": "Unisender Go Api Key",
    "admin.print-settings.print-settings-style": "Отображение предложения печати",
    "admin.print-settings.print-settings-style-calc": "Калькулятор печати",
    "admin.print-settings.print-settings-style-redirect-button": "Кнопка с перенаправлением",
    "admin.print-settings.print-settings-style-hidden": "Не отображать",
    "admin.print-settings.redirect-url": "Адрес перенаправления",
    "admin.print-settings.redirect-url-description": "Вы можете указать ссылку, куда будет перенеправлен пользователь при клике на кнопку \"Перейти к печати\". Формат указания ссылки \"http://yourdomain/books/[id]/preview\". Доступные значения: id, integrationProjectId, isbn, width, height",
    "superadmin.auth.auth-error": "Не удалось авторизоваться",
    "superadmin.companies-settings.common": "Общие",
    "superadmin.companies-settings.admins": "Администраторы",
    "superadmin.companies-settings.add-admin": "Добавить администратора",
    "superadmin.companies-settings.add-admin-email": "Email",
    "superadmin.companies-settings.account": "Личный кабинет",
    "admin.companies.company.edition-notice-settings.template": "Шаблон страницы выходных данных",
    "admin.companies.company.edition-notice-settings.template-small": "Шаблон маленькой страницы выходных данных",
    "admin.companies.company.edition-notice-settings.preview": "Превью страницы выходных данных",
    "admin.companies.company.edition-notice-settings-not-found": "Настройки выходных данных не найдены",
    "admin.companies.company.edition-notice-empty-fields": "Добавьте файлы для всех языков",
    "admin.companies.company.edition-notice.legel-name": "Юридическое название компании",
    "admin.companies.company.edition-notice.project-name": "Название компании",
    "admin.companies.company.edition-notice.project-description": "Описание компании",
    "admin.moderation.images.queue.no-images": "Изображения для модерации отсутствуют",
    "admin.moderation.images.generation-data.prompt": "Сюжет",
    "admin.moderation.images.accept": "Добавить в коммьюнити",
    "admin.moderation.images.decline": "Отклонить заявку",
    "admin.moderation.images.description-placeholder": "Введите причину",
    "admin.moderation.images.description-options.0": "Другое:",
    "admin.moderation.images.description-options.1": "Нарушение авторских прав",
    "admin.moderation.images.description-options.2": "Неприемлемый контент",
    "admin.moderation.images.description-options.3": "Без указания причины",
    "admin.moderation.images.filter-options.user": "От пользователя",
    "admin.moderation.images.filter-options.free-tariff": "Из бесплатного тарифа",
    "admin.moderation.images.error.update": "Ошибка при обновлении данных изображения",
    "admin.moderation.covers.queue.no-covers": "Обложки для модерации отсутствуют",
    "admin.moderation.covers.generation-data.prompt": "Сюжет",
    "admin.moderation.covers.accept": "Добавить в коммьюнити",
    "admin.moderation.covers.decline": "Отклонить заявку",
    "admin.moderation.covers.description-placeholder": "Введите причину",
    "admin.moderation.covers.description-options.0": "Другое:",
    "admin.moderation.covers.description-options.1": "Нарушение авторских прав",
    "admin.moderation.covers.description-options.2": "Неприемлемый контент",
    "admin.moderation.covers.filter-options.user": "От пользователя",
    "admin.moderation.covers.filter-options.free-tariff": "Из бесплатного тарифа",
    "admin.moderation.covers.error.update": "Ошибка при обновлении данных обложки",
    "admin.account-main.buttons.title": "Кнопки админа",
    "admin.account-main.buttons.notification-test.button": "Тест уведомления",
    "admin.account-main.buttons.notification-test.image-moderation-success.option": "Успешная модерация изображения",
    "admin.account-main.buttons.notification-test.image-moderation-fail.option": "Неуспешная модерация изображения",
    "admin.account-main.buttons.notification-test.cover-moderation-success.option": "Успешная модерация обложки",
    "admin.account-main.buttons.notification-test.cover-moderation-fail.option": "Неуспешная модерация обложки",
    "admin.companies.company.telegram-settings.tilte": "Настройки подключения Telegram",
    "admin.companies.company.telegram-settings.can-connect": "Возможность подключения Telegram",
    "admin.companies.company.telegram-settings.channel-id": "ID Telegram канала",
    "admin.companies.company.telegram-settings.channel-url": "URL Telegram канала",
    "admin.companies.company.telegram-settings.bot-url": "URL Telegram бота",
    "admin.companies.company.maintenance-settings.tilte": "Настройки обслуживания",
    "admin.companies.company.maintenance-settings.image-generation.basic": "Базовый режим генерации",
    "admin.companies.company.maintenance-settings.image-generation.advanced": "Расширенный режим генерации",
    "admin.companies.company.maintenance-settings.action-enable-description": "Включен режим обслуживания",
    "admin.companies.company.maintenance-settings.action-disable-description": "Отключен режим обслуживания",
    "admin.format": "Форматы",
    "admin.format.add-format": "Добавить формать",
    "admin.format.add-format.title": "Название",
    "admin.format.add-format.value": "Обозначение",
    "admin.format.add-format.type": "Тип",
    "admin.format.add-format.width": "Ширина",
    "admin.format.add-format.height": "Высота",
    "admin.format.add-format.is-available-to-user": "Доступен пользователям",
    "admin.format.add-format.is-available-to-other-companies": "Доступен компаниям",
    "admin.format.hide-format-confirmation": "Скрыть формат?",
    "admin.format.buttons.add-format": "Новый формат",
    "admin.format.error.cant-add-format": "Не удалось добавить формат",
    "admin.format.error.cant-delete-format": "Не удалось удалить формат",
    "admin.format.error.cant-update-format": "Не удалось обновить формат",
    "admin.format.delete-format-confirmation": "Удалить формат",
    "admin.rewards.onetime.add": "Добавить разовую награду",
    "admin.rewards.onetime.delete-confirmation": "Удалить разовую награду?",
    "admin.rewards.onetime.error.cant-create": "Не удалось добавить разовую награду",
    "admin.rewards.onetime.error.cant-delete": "Не удалось удалить разовую награду",
    "admin.rewards.onetime.error.cant-update": "Не удалось обновить разовую награду",
    "admin.rewards.onetime.type": "Тип задания",
    "admin.rewards.onetime.limit": "Количество действий",
    "admin.rewards.onetime.reward-credits-count": "Вознаграждение (токены)",
    "admin.rewards.daily.add": "Добавить ежедневную награду",
    "admin.rewards.daily.delete-confirmation": "Удалить ежедневную награду?",
    "admin.rewards.daily.error.cant-create": "Не удалось добавить ежедневную награду",
    "admin.rewards.daily.error.cant-delete": "Не удалось удалить ежедневную награду",
    "admin.rewards.daily.error.cant-update": "Не удалось обновить ежедневную награду",
    "admin.rewards.daily.type": "Тип задания",
    "admin.rewards.daily.limit": "Количество действий",
    "admin.rewards.daily.reward-credits-count": "Вознаграждение (токены)",
    "admin.rewards.daily.task.likes.take": "Полученные лайки",
    "admin.rewards.daily.task.likes.give": "Поставленные лайки",
    "admin.rewards.onetime.task.subscribe-to-telegram-channel": "Подписаться на телеграм канал",
    "onboarding.buttons.next": "Далее",
    "onboarding.buttons.back": "Назад",
    "onboarding.buttons.complete": "Завершить",
    "onboarding.buttons.cancel": "Отмена",
    "onboarding.buttons.close": "Закрыть",
    "onboarding.title": "Обучение",
    "onboarding.upload-page.title": "Загрузка документа",
    "onboarding.upload-page.text-step-1": "Загрузите свою рукопись в формате .docx или создайте пустой документ",
    "onboarding.templates-page.title": "Шаблоны",
    "onboarding.templates-page.text-step-1": "Укажите желаемый формат вашего издания, ограничений по форматам нет",
    "onboarding.templates-page.text-step-2": "Из предложенных шаблонов выбирете один или начните создание своего проекта с чистого листа",
    "onboarding.markup-page.title": "Разметка",
    "onboarding.markup-page.text-step-1": "Для глобального изменения размеров текста воспользуйтесь этим ползунком. Размер основного текста останется без изменений.",
    "onboarding.markup-page.text-step-2": "Для точечного изменения размеров текста, нажмите на шестеренку напротив нужного стиля.",
    "onboarding.markup-page.text-step-3": "Помимо изменения размера текста, можно настроить стиль оформления абзаца: начертание, выравнивание, регистр и местоположение. Изменение местоположения доступно только для стилей текста \"Раздел\" и \"Глава\"",
    "onboarding.markup-page.text-step-4": "С этой настройкой заголовок начинается с новой страницы",
    "onboarding.markup-page.text-step-5": "А с этой – с нечетной страницы (правой страницы разворота)",
    "onboarding.markup-page.text-step-6": "Во вкладке \"Дизайн\" вы можете изменить цвета вашего проекта и выбрать принцип расстановки изображений",
    "onboarding.markup-page.text-step-7": "Вы можете выбрать один из вариантов палитры",
    "onboarding.markup-page.text-step-8": "И изменить его по своему вкусу, нажав на шестеренку",
    "onboarding.markup-page.text-step-9": "Для включения содержания в верстку, включите этот параметр и укажите местоположение содержание. Оно сгенерится автоматически на основании заголовков вашего издания",
    "onboarding.markup-page.text-step-10": "Для добавления новых блоков или разрыва страницы используйте эту кнопку, она появится при клике на любой блок в разметке",
    "onboarding.markup-page.text-step-11": "Для изменения положения блока или его удаления, кликните на эту кнопку и выберите нужное действие",
    "onboarding.margins-page.title": "Поля",
    "onboarding.margins-page.text-step-1": "Настраивайте свой макет, добавляйте многоколоночный режим, дополнительное широкое поле и назначайте ширину внешних полей",
    "onboarding.running-titles-page.title": "Колонтитулы",
    "onboarding.running-titles-page.text-step-1": "Настраивайте колонтитулы и нумерацию страниц, выбирайте их местоположение и содержимое",
    "onboarding.preview-page.title": "Предварительный просмотр",
    "onboarding.preview-page.text-step-1": "На этом экране сервис демонстрирует первые страницы вашего издания. Если вас все устраивает, нажимайте на кнопку \"Сверстать книгу\", начнется верстка всей книги",
    "onboarding.books-page.title": "Проекты",
    "onboarding.books-page.text-step-1": "Тут вы можете изменить название книги, имя автора, год и город издания вашего проекта",
    "onboarding.books-page.text-step-2": "После завершения вёрстки книги, кликнув по этой кнопке вы сможете скачать архив со всеми форматами: .pdf для печати и предварительного просмотра, рабочая сборка проекта в InDesign.",
    "onboarding.books-page.text-step-3": "Кликнув по этой кнопке, вы можете перейти в редактирование проекта, где можно изменить данные анкеты и попасть на разметку книги",
    "onboarding.books-page.text-step-4": "Дублирование проекта. ",
    "onboarding.books-page.text-step-5": "Удаление проекта. Будьте внимательны, остановить удаление или вернуть удаленный проект невозможно.",
    "onboarding.home-page.title": "Генерации сообщества",
    "onboarding.home-page.text-step-1": "Добро пожаловать в «Генерации сообщества», здесь вы можете познакомиться с иллюстрациями, сгенерированными другими пользователями. \r\nЭти изображения прошли нашу модерацию, а значит — достойны появления на главной странице сервиса.\r\n",
    "onboarding.home-page.text-step-2": "В описании каждой иллюстрации можно увидеть параметры, которые были заданы пользователями.",
    "onboarding.home-page.text-step-3": "Если вам понравилась иллюстрация, вы можете использовать эти настройки в своей работе \r",
    "onboarding.home-page.text-step-4": "Здесь вы можете увидеть ваши проекты, над которыми вы работали ",
    "onboarding.home-page.text-step-5": "Здесь вы можете начать работу над иллюстрациями\r",
    "onboarding.image-generation-page.title": "Генерация изображений",
    "onboarding.image-generation-basic.title": "Базовые настройки",
    "onboarding.image-generation-advanced.title": "Расширенные настройки ",
    "onboarding.image-generation-creative.title": "Креативные настройки ",
    "onboarding.image-generation-page.text-step-1": "Сервис имеет два вида настроек.\n«Основные» — базовые опции для новичков, позволяющие быстро добиться результата без особого погружения",
    "onboarding.image-generation-page.text-step-2": "«Расширенные» — включают функции базовых настроек, но дополнены профессиональной детализацией для генерации",
    "onboarding.image-generation-page.text-step-3": "Выберите пропорции и ориентацию изображений.",
    "onboarding.image-generation-page.text-step-4": "Укажите количество вариантов изображений. Учтите, что это влияет на стоимость генерации",
    "onboarding.image-generation-page.text-step-5": "Укажите основную идею — запрос, что вы хотите получить на выходе после генерации. Это может быть описание ключевой сцены, образ героя или абстрактный фразеологизм. Всё, что вы хотели бы увидеть на обложке или иллюстрации\n",
    "onboarding.image-generation-page.text-step-6": "Выберите один из множества стилей. Если вы готовите иллюстрации для сюжета в книгу, рекомендуем использовать один стиль. Так вы сохраните единый визуальный характер",
    "onboarding.image-generation-page.text-step-7": "Укажите степень детализации изображения. Ползунок слева — минимализм, справа — высокая детализация",
    "onboarding.image-generation-page.text-step-8": "Уточните настроение изображения",
    "onboarding.image-generation-page.text-step-9": "Быстро — идеально подходит для поиска идей и черновиков с меньшими затратами токенов. Лучше — требует больше времени, но дает более детализированные результаты. Ультра — максимальный размер и уровень качества изображений.",
    "onboarding.image-generation-page.text-step-10": "Уточните цветовую гамму. Например, только черно-белые или сине-красные тона. Здесь можно задать эти настройки нейросети",
    "onboarding.image-generation-page.text-step-11": "Укажите, чего не должно быть в основном сюжете, и удалите лишние элементы. Работайте с готовыми изображениями, чтобы получить идеальный финальный вариант",
    "onboarding.image-generation-page.text-step-12": "Экспериментируйте с выбором моделей. Результат генерации зависит от выбранной модели. Сервис предлагает рекомендуемую модель в зависимости от техники и степени реалистичности",
    "onboarding.image-generation-page.text-step-13": "Экспериментируйте с режимами генерации. Выбрав другой режим, вы получите новые результаты",
    "onboarding.image-generation-page.text-step-14": "Количество списываемых токенов зависит от количество вариантов иллюстраций",
    "onboarding.image-generation-page.text-step-15": "Добавьте персонажей. Эта функция создана для сохранения внешнего вида персонажей между генерациями",
    "onboarding.image-generation-page.text-step-16": "Функция \"Повтор лица\" генерирует изображения с выбранным лицом. Используйте только человеческие образы — животные, роботы и трансформеры не подходят.",
    "onboarding.modal-book-templates.title": "Выбор типа проекта",
    "onboarding.modal-book-templates.text-step-1": "Создайте обложку для вашего проекта",
    "onboarding.cover-page.title": "Редактор обложек",
    "onboarding.cover-page.text-step-1": "Включите рабочую видимую область обложки. Не рекомендуем, чтобы текст и важные элементы выходили за её пределы",
    "onboarding.cover-page.text-step-2": "Добавляйте в макет необходимые элементы: текст, изображения, фигуры",
    "onboarding.cover-page.text-step-3": "Меняйте позиции слоёв. Чем выше слой в списке, тем выше он находится в рабочей области",
    "onboarding.cover-page.text-step-4": "Кликая на нужный слой, можно перетаскивать его, менять угол наклона и настраивать такие параметры, как шрифт, цвет, размер и выравнивание текста",
    "onboarding.cover-page.text-step-5": "Здесь вы можете выбрать или изменить шрифт, цвет текста, его размер, задать фиксированную ширину и высоту строки. Также можно настроить выравнивание: по центру, по левому или по правому краю",
    "onboarding.cover-page.text-step-6": "Когда обложка яркая и пёстрая, текст может сливаться с изображением. Чтобы этого избежать, можно добавить на задний фон плашку — фигуру. В рабочей области фигуры можно растягивать и назначать им цвета обводки",
    "onboarding.cover-page.text-step-7": "Любой элемент (текст, изображение, фигура) можно закрепить или удалить в слоях. Закрепление предотвращает случайное перемещение элементов. Это делается с помощью иконок в рабочей области",
    "cover.breadcrumb.create-step": "Создать обложку",
    "cover.breadcrumb.generation-step": "Генерация изображения",
    "cover.breadcrumb.crop-step": "Кадрирование",
    "cover.breadcrumb.preview-step": "Предпросмотр",
    "image-generation.results.results-will-be-shown-here": "Здесь будут ваши генерации изображений",
    "image-generation.results.no-results.enter-prompt": "Введите ваш запрос и нажмите",
    "image-generation.results.no-results.press-generate": "Генерировать",
    "image-generation.image.preview-button": "Предпросмотр",
    "image-generation.image.select-button": "Выбрать",
    "image-generation.image.publish-image-button": "Опубликовать",
    "image-generation.sidebar.tabs.basic": "Базовый",
    "image-generation.sidebar.tabs.advanced": "Расширенный",
    "image-generation.sidebar.tabs.creative": "Креативный",
    "image-generation.style-details.apply": "Применить",
    "image-generation.style-details.selected-style": "Выбранный стиль:",
    "image-generation.image-size": "Соотношение сторон",
    "image-generation.image-size.width": "Ширина",
    "image-generation.image-size.height": "Высота",
    "image-generation.image-count": "Количество изображений",
    "image-generation.advanced-generation-mode": "Уровень качества",
    "image-generation.prompt": "Введите желаемый сюжет",
    "image-generation.prompt.placeholder": "Введите желаемый сюжет",
    "image-generation.select-style": "Выберите стиль",
    "image-generation.show-all-styles": "все",
    "image-generation.generate": "Генерировать",
    "image-generation.styles-restriction.advanced.error-text-1": "Для функции повтора лица выбирайте только человеческие образы — животные, роботы и трансформеры не подойдут. Попробуйте ",
    "image-generation.styles-restriction.advanced.error-action": "креативный режим",
    "image-generation.styles-restriction.advanced.error-text-2": "генерации для получения альтернативных результатов!",
    "image-generation.styles-restriction.creative.error-text-1": "Для функции повтора лица выбирайте только человеческие образы — животные, роботы и трансформеры не подойдут. Попробуйте",
    "image-generation.styles-restriction.creative.error-action": "базовый режим",
    "image-generation.styles-restriction.creative.error-text-2": "генерации для получения альтернативных результатов!",
    "image-generation.selected-mode": "Режим",
    "image-generation.selected-mode.basic": "Базовый",
    "image-generation.selected-mode.advanced": "Расширенный",
    "image-generation.selected-mode.creative": "Креативный",
    "image-generation.selected-mode.face-swap": "Замена лица",
    "image-generation.selected-style": "Cтиль",
    "image-generation.selected-mood": "Настроение",
    "image-generation.selected-detalization": "Детализация",
    "image-generation.selected-quality-level": "Уровень качества",
    "image-generation.select-style.group.graphics": "Графика",
    "image-generation.select-style.group.painting": "Живопись",
    "image-generation.select-style.group.digital": "Цифровое",
    "image-generation.select-style.group.photo": "Фото",
    "image-generation.select-style.group.children-drawing": "Детское",
    "image-generation.select-style.group.fantastic": "Фантастика",
    "image-generation.select-style.group.comics": "Комиксы",
    "image-generation.select-style.group.all": "Все",
    "image-generation.select-style.group.premium": "Премиум",
    "image-generation.select-style.all-styles": "Все стили",
    "image-generation.select-style.all-techniques": "Все техники",
    "image-generation.generation.time.seconds": "сек",
    "image-generation.detalization": "Детализация",
    "image-generation.detalization.min": "Минимум",
    "image-generation.detalization.normal": "Умеренно",
    "image-generation.detalization.max": "Ультра",
    "image-generation.detalization-value.min": "Минимум",
    "image-generation.detalization-value.normal": "Умеренно",
    "image-generation.detalization-value.max": "Ультра",
    "image-generation.image-advanced-generation-mode.fast": "Быстро",
    "image-generation.image-advanced-generation-mode.quality": "Лучше",
    "image-generation.image-advanced-generation-mode.ultra": "Ультра",
    "image-generation.mood": "Настроение",
    "image-generation.mood.devilish": "Дьявольское",
    "image-generation.mood.evil": "Злое",
    "image-generation.mood.crazy": "Сумасшедшее",
    "image-generation.mood.dramatic": "Драматичное",
    "image-generation.mood.sad": "Грустное",
    "image-generation.mood.mystical": "Мистическое",
    "image-generation.mood.neutral": "Нейтральное",
    "image-generation.mood.heroic": "Героическое",
    "image-generation.mood.romantic": "Романтичное",
    "image-generation.mood.joyful": "Радостное",
    "image-generation.mood.happy": "Счастливое",
    "image-generation.mood.error": "Выберите настроение",
    "image-generation.age": "Эпоха",
    "image-generation.age.stone-age": "Каменный век",
    "image-generation.age.bronze-age": "Бронзовый век",
    "image-generation.age.iron-age": "Железный век",
    "image-generation.age.antiquity": "Античность",
    "image-generation.age.middle-ages": "Средневековье",
    "image-generation.age.renaissance": "Возрождение",
    "image-generation.age.industrialization": "Индустриализация",
    "image-generation.age.modernity": "Современность",
    "image-generation.age.future": "Будущее",
    "image-generation.age.set-year": "Указать год",
    "image-generation.realistic": "Реалистичность",
    "image-generation.realistic.abstract": "абстрактный",
    "image-generation.realistic.realistic": "реалистичный",
    "image-generation.contrast": "Контраст",
    "image-generation.contrast.weak": "слабый",
    "image-generation.contrast.strong": "сильный",
    "image-generation.realistic-value.hyperabstract": "Абстрактный",
    "image-generation.realistic-value.abstract": "Более абстрактный",
    "image-generation.realistic-value.styled": "Стилизованный",
    "image-generation.realistic-value.realistic": "Более реалистичный",
    "image-generation.realistic-value.hyperrealistic": "Реалистичный",
    "image-generation.selected-technique": "Выбранная техника:",
    "image-generation.technique": "Техника",
    "image-generation.technique.none": "Отсутствует",
    "image-generation.color-scheme": "Цветовая схема",
    "image-generation.color-scheme-value.fullcolor": "Полноцветная",
    "image-generation.color-scheme-value.black-and-white": "Черно-белая",
    "image-generation.color-scheme-value.colors": "Свои цвета:",
    "image-generation.model": "Модель",
    "image-generation.mode": "Режим",
    "image-generation.negative-prompt": "Негативный запрос",
    "image-generation.dynamic-composition": "Динамичная композиция",
    "image-generation.advanced-generation-mode.info.1.title": "Быстро",
    "image-generation.advanced-generation-mode.info.1.text": "Идеально для поиска идей и набросков, минимальные затраты токенов.",
    "image-generation.advanced-generation-mode.info.2.title": "Лучше",
    "image-generation.advanced-generation-mode.info.2.text": "Улучшенное качество и деталлизация, больший размер изображений.",
    "image-generation.advanced-generation-mode.info.3.title": "Ультра",
    "image-generation.advanced-generation-mode.info.3.text": "Максимальный размер и уровень качества изображений.",
    "image-generation.generation.repeat-generation": "Повторить генерацию",
    "image-generation.generation.edit-generation": "Изменить детали",
    "image-generation.generation.edit-generation.soon-text": "",
    "image-generation.generation.delete-generation": "Удалить генерацию",
    "image-generation.generation.error": "Ошибка генерации изображения",
    "image-generation.generation.error.image-generation-not-found": "Генерация не найдена",
    "image-generation.generation.error.while-processing": "Произошла ошибка во время генерации. Токены возвращены на Ваш счет.",
    "image-generation.generation.cant-start-processing-error": "Не удалось запустить генерацию изображения",
    "image-generation.generation.variant-image.unzoom.start": "Расширение изображения запущено. Результат можно выбрать в выпадающем списке",
    "image-generation.generation.variant-image.unzoom-mj.start": "Расширение изображения запущено. 4 варианта расширения можно выбрать в выпадающем списке",
    "image-generation.generation.variant-image.unzoom.error": "Произошла ошибка при расширении изображения",
    "image-generation.generation.variant-image.unzoom.error.xl-model": "Для выбранной модели расширение недоступно",
    "image-generation.generation.variant-image.unzoom.error.xl-model-basic": "Для выбранной модели расширение недоступно. Попробуйте другой стиль",
    "image-generation.generation.variant-image.unzoom.error.xl-model-advanced": "Для выбранной модели расширение недоступно. Попробуйте другую модель",
    "image-generation.generation.variant-image.unzoom.error.megapixel-limit": "Слишком большой размер изображения",
    "image-generation.generation.variant-image.upscale.start": "Улучшение изображения запущено. Результат можно выбрать в выпадающем списке",
    "image-generation.generation.variant-image.upscale.error": "Произошла ошибка при улучшении изображения",
    "image-generation.generation.variant-image.upscale.error.megapixel-limit": "Слишком большой размер изображения",
    "image-generation.generation.variant-image.upscale.error.re-upscale-limit": "В настоящий момент повторное улучшение недоступно",
    "image-generation.generation.variant-image.nobg.start": "Улучшение изображения запущено. Результат можно выбрать в выпадающем списке",
    "image-generation.generation.variant-image.nobg.error": "Произошла ошибка при удалении фона с изображения",
    "image-generation.generation.variant-image.nobg.error.megapixel-limit": "Слишком большой размер изображения",
    "image-generation.generated-image.cant-load-image-error": "Не удалось загрузить изображение",
    "image-generation.generation.variant-image.original": "Оригинал",
    "image-generation.generation.variant-image.upscale": "Улучшение",
    "image-generation.generation.variant-image.unzoom": "Расширение",
    "image-generation.generation.variant-image.nobg": "Без фона",
    "image-generation.generation.tooltip.not-enought-tokens": "Недостаточно токенов.",
    "image-generation.generation.tooltip.text": "Ознакомьтесь с ",
    "image-generation.generation.tooltip.text.action": "тарифными планами",
    "image-generation.generation.payments-modal.header-01": "Стоимость генерации:",
    "image-generation.generation.payments-modal.header-02": "Стоимость расширения:",
    "image-generation.generation.payments-modal.header-03": "Стоимость улучшения:",
    "image-generation.generation.payments-modal.header-04": "Стоимость удаления фона:",
    "image-generation.generation.delete-generation-confirmation": "Удалить генерацию?",
    "image-generation.generation.delete-generated-image-confirmation": "Удалить изображение?",
    "image-generation.generation.delete-generated-image-with-child-generations-confirmation": "Удалить оригинальное изображение и все дочерние?",
    "image-generation.generation.download.button-description": "Скачать",
    "image-generation.generation.delete.button-description": "Удалить",
    "image-generation.generation.processing": "Обработка...",
    "image-generation.generation.unzoom.processing": "Расширение изображения...",
    "image-generation.generation.unzoom.title": "Расширить изображение",
    "image-generation.generation.unzoom.info": "Если вам нужно добавить деталей вокруг вашего текущего изображения, тем самым увеличив его общую область.",
    "image-generation.generation.unzoom.button-text": "Расширить",
    "image-generation.generation.unzoom.button-description": "Расширить",
    "image-generation.generation.upscale.processing": "Улучшение изображения...",
    "image-generation.generation.upscale.title": "Улучшить изображение",
    "image-generation.generation.upscale.info": "Доработать мелкие детали и исправить недочеты на лицах, руках и др.",
    "image-generation.generation.upscale.button-text": "Улучшить ",
    "image-generation.generation.upscale.button-description": "Улучшить ",
    "image-generation.generation.upscale.strength": "Выберите степень улучшения ниже:",
    "image-generation.generation.upscale.strength-description.min": "Минимум",
    "image-generation.generation.upscale.strength-description.normal": "Нормально",
    "image-generation.generation.upscale.strength-description.max": "Ультра",
    "image-generation.generation.nobg.processing": "Удаление фона...",
    "image-generation.generation.nobg.title": "Удалить фон",
    "image-generation.generation.nobg.info": "Если вам нужно удалить фон.",
    "image-generation.generation.nobg.button-text": "Удалить фон",
    "image-generation.generation.nobg.button-description": "Удалить фон",
    "image-generation.generation.default-prompt.1": "Детектив в переулке ночью.",
    "image-generation.generation.default-prompt.2": "Неоновый футуристичный город.",
    "image-generation.generation.default-prompt.3": "Волшебник творит заклинание.",
    "image-generation.generation.default-prompt.4": "Сыщик на мостовой.",
    "image-generation.generation.default-prompt.5": "Летающие машины в мегаполисе.",
    "image-generation.generation.default-prompt.6": "Фея над цветущим лугом.",
    "image-generation.generation.default-prompt.7": "Колония на другой планете.",
    "image-generation.generation.default-prompt.8": "Мистический лес с рунами.",
    "image-generation.generation.default-prompt.9": "Роботы гуляют в парке.",
    "image-generation.generation.helper.description": "Для более точного повторения сюжета используйте расширенные настройки",
    "generated-image.best-generated-images.title": "Генерации сообщества",
    "generated-image.results.results-will-be-shown-here": "Здесь будут изображения сообщества",
    "published-image.settings.style": "Стиль",
    "published-image.settings.prompt": "Сюжет",
    "published-image.settings.negative-prompt": "Негативный промпт",
    "published-image.settings.detalization": "Детализация",
    "published-image.settings.realistic": "Реалистичность",
    "published-image.settings.mood": "Настроение",
    "published-image.settings.model": "Модель",
    "published-image.settings.mode": "Режим",
    "published-image.settings.color-scheme": "Цветовая схема",
    "published-image.settings.creation-date": "Дата создания",
    "published-image.settings.used-style-reference": "В оригинальном изображении был применен стиль референс",
    "published-image.settings.used-style-reference-2": "Было использовано дополнительное изображение в качестве референс стиля",
    "published-image.settings.face-swap-tooltip": "Заменить лицо",
    "published-image.settings.use-current-settings": "Повторить",
    "image-generation.generation.prompt-restriction": "Мы не позволяем генерировать изображения связанные с политикой",
    "published-image.delete-published-image-confirmation": "Удалить публикацию?",
    "published-image.share-image.header": "Поделиться",
    "published-image.share-image.description": "Отправить ваше изображение <br>на модерацию в сообщество",
    "published-image.share-image.share-button-text": "Отправить",
    "published-image.share-image.share-socials": "или поделиться в",
    "published-image.share-image.moderation-processing": "Ваше изображение отправлено на модерацию.",
    "published-image.share-image.moderation-accepted": "Ваше изображение принято и опубликовано в сообществе.",
    "published-image.share-image.moderation-declined": "Ваше изображение было отклонено. <br>Причина: ",
    "published-image.share-image.moderation-declined.without-reason": "Ваше изображение было отклонено.",
    "characters.notofication.only-in-advanced-generation": "Персонажи доступны только в расширенных генерациях",
    "characters.new.title": "Новый персонаж",
    "characters.new.name": "Имя",
    "characters.new.gender": "Пол",
    "characters.new.gender.male": "Мужской",
    "characters.new.gender.female": "Женский",
    "characters.new.age": "Возраст",
    "characters.new.type": "Тип персонажа",
    "characters.new.type.human": "Человек",
    "characters.new.type.robot": "Робот",
    "characters.new.type.cyborg": "Киборг",
    "characters.new.type.elf": "Эльф",
    "characters.new.type.dwarf": "Гном",
    "characters.new.type.troll": "Тролль",
    "characters.new.type.orc": "Орк",
    "characters.new.type.demon": "Демон",
    "characters.new.type.custom": "Свой",
    "characters.new.hair": "Волосы",
    "characters.new.hair.placeholder": "Тёмные и длинные",
    "characters.new.bald": "Лысый",
    "characters.new.appearance": "Внешний вид",
    "characters.new.appearance.placeholder": "Опишите особые приметы, например: черты лица; одежда; украшения",
    "characters.new.create-button": "Создать персонажа",
    "characters.edit.title": "Редактировать персонажа",
    "characters.edit.save-button": "Сохранить изменения",
    "characters.edit.select-button": "Выбрать",
    "characters.list.title": "Мои персонажи",
    "characters.list.add-new-button": "Создать <br>персонажа",
    "characters.list.info.1": "Черты лица, созданного вами персонажа могут меняться в каждой генерации, но их особенности внешности сохранятся.",
    "characters.list.info.2-1": "Одновременно в вашем сюжете можно использовать не более",
    "characters.pluralize.unique": "{value, plural, zero {уникальных} one {уникальный} two {уникальных} few {уникальных} many {уникальных} other {уникальных}} ",
    "characters.pluralize.characters": "{value, plural, zero {персонажей} one {персонаж} two {персонажа} few {персонажей} many {персонажей} other {персонажей}} ",
    "characters.list.select": "Выбрать",
    "characters.list.already-selected": "Уже выбрано",
    "characters.list.limit-reached": "Достигнут лимит",
    "characters.delete-confirmation.title": "Подтвердите действие",
    "characters.delete-confirmation.text": "Вы действительно хотите удалить персонажа",
    "characters.delete-confirmation.delete-button": "Удалить персонажа",
    "characters.generation.error.cant-start-processing-error": "Не удалось создать персонажа",
    "characters.generation.error.error": "Ошибка создания персонажа",
    "characters.copy.error": "Ошибка при копировании персонажа",
    "characters.generation.processing": "Генерация...",
    "characters.card.edit.button-description": "Редактировать",
    "characters.card.copy.button-description": "Копировать",
    "characters.card.delete.button-description": "Удалить",
    "characters.generation.error.name-must-be-unique-error": "Имя персонажа должно быть уникальным",
    "characters.generation.character-template-text": "Персонаж",
    "characters.generation.character-select-text": "Выберите персонажа",
    "characters.popup.create": "Создать",
    "characters.not-existing.error.text-1": "Сведения о персонаже отсутствуют.",
    "characters.not-existing.error.action.select": "Выберите",
    "characters.not-existing.error.text-2": "из ранее созданных или",
    "characters.not-existing.error.action.create": "создайте нового.",
    "characters.limit.error.text": "Максимально допустимое количество персонажей в сюжете:",
    "characters.not-existing.error.notification": "Сведения о персонаже отсутствуют. Выберите из ранее созданных или создайте нового.",
    "characters.limit.error.notification": "Максимально допустимое количество персонажей в сюжете:",
    "style-reference-upload-modal.title": "Загрузить своё изображение",
    "style-reference-upload-modal.description": "Используйте любое изображение как стиль для генерации ",
    "style-reference-upload-modal.style-repeatability.title": "Повторяемость стиля",
    "style-reference-upload-modal.style-repeatability.min": "Минимум",
    "style-reference-upload-modal.style-repeatability.mid": "Средне",
    "style-reference-upload-modal.style-repeatability.max": "Максимально",
    "style-reference-upload-modal.apply-button": "Применить как стиль",
    "style-reference-upload-modal.upload-button.text-1": "Перетащите сюда",
    "style-reference-upload-modal.upload-button.text-2": "или",
    "style-reference-upload-modal.upload-button.text-2-upload": "загрузите",
    "style-reference-upload-modal.upload-button.text-3": "файл",
    "style-reference-upload-modal.upload-another-button.text-1-upload": "Загрузить",
    "style-reference-upload-modal.upload-another-button.text-2": "другой файл",
    "image-generation.style-reference.without-image.title": "Использовать <br>стиль с изображения",
    "image-generation.style-reference.with-image.title": "Свой стиль",
    "image-generation.style-reference.replace": "Заменить",
    "image-generation.style-reference.upload.request.success": "Стиль создан успешно",
    "image-generation.style-reference.upload.request.error": "Произошла ошибка при создании стиля",
    "image-generation.style-reference.upload.request.error.file-limit": "Размер файла слишком большой",
    "image-generation.style-reference.upload.request.error.file-extension": "Файл данного типа не подходит",
    "image-generation.style-reference.upload.process-hint": "Стиль создается",
    "image-generation.style-reference.upload.no-image-file.error": "Отсутствует файл изображение ",
    "image-generation.maintenance.title": "Режим недоступен",
    "image-generation.maintenance.basic.description": "Мы проводим обновление сервиса и некоторые функции могут быть недоступны. <br><br>Воспользуйтесь базовым режимом генерации или попробуйте открыть эту страницу позднее",
    "image-generation.maintenance.creative.description": "Мы проводим обновление сервиса и некоторые функции могут быть недоступны. <br><br>Воспользуйтесь креативным режимом генерации или попробуйте открыть эту страницу позднее",
    "image-generation.maintenance.error": "Мы проводим обновление сервиса и некоторые функции могут быть недоступны. Воспользуйтесь другим режимом генерации или попробуйте открыть эту страницу позднее",
    "image-generation.maintenance.action-basic": "Базовый режим",
    "image-generation.maintenance.action-advanced": "Расширенный режим",
    "image-generation.maintenance.action-creative": "Креативный режим",
    "image-generation.selected-style.user-style-reference": "Свой стиль",
    "image-generation.form.error": "Пожалуйста, выберите все настройки",
    "image-generation.form.prompt.min-length-limit.error": "Введите сюжет",
    "image-generation.form.prompt.max-length-limit.error": "Превышена допустимое количество символов в сюжете",
    "image-generation.form.negative-prompt.max-length-limit.error": "Превышена допустимое количество символов негативного запроса",
    "image-generation.style-reference-not-compatible.error": "Выбранный режим генерации не совместим со своим стилем",
    "image-generation.character-reference.modal.tab-1": "Повтор лица",
    "image-generation.character-reference.modal.tab-2": "Повтор персонажа",
    "image-generation.character-reference.modal.delete-button": "Удалить",
    "image-generation.character-reference.modal.generation.title": "Загрузите или выберите фото для замены лица",
    "image-generation.character-reference.modal.generation.info": "Если на изображении нет лиц, функция «Замена лица» работать не будет.",
    "image-generation.character-reference.modal.generation.face-swap-button": "Заменить лицо",
    "image-generation.character-reference.modal.card.select-button": "Выбрать",
    "image-generation.character-reference.preview-modal.title": "Кадрируйте зону лица",
    "image-generation.character-reference.preview-modal.save-button": "Сохранить и продолжить",
    "image-generation.character-reference.upload.request.error": "Произошла ошибка при загрузке изображения",
    "image-generation.character-reference.upload.request.error.file-limit": "Размер файла слишком большой",
    "image-generation.character-reference.upload.request.error.file-extension": "Файл данного типа не подходит",
    "image-generation.character-reference.upload.process-hint": "Стиль повтора лица создается",
    "image-generation.character-reference.upload.no-image-file.error": "Отсутствует файл изображения",
    "image-generation.character-reference.upload.request.success": "Изображение загружено",
    "image-generation.character-reference.delete-confirmation": "Удалить изображение?",
    "image-generation.generation.face-swap.prompt": "Замена лица",
    "cover-editor.sidebar.cover-objects": "Элементы обложки",
    "cover-editor.sidebar.create-object": "Добавить",
    "cover-editor.sidebar.layers": "Слои",
    "cover-editor.sidebar.images.AI.caption": "Генерация с AI",
    "cover-editor.sidebar.images.AI.description": "Нейросеть поможет воплотить все ваши гениальные идеи",
    "cover-editor.sidebar.images.local.caption": "Загрузить своё",
    "cover-editor.sidebar.images.local.description": "Использовать уже существующее изображение с вашего устройства",
    "cover-editor.sidebar.shapes": "Фигуры",
    "cover-editor.sidebar.next": "Далее",
    "cover-editor.toolbar.color": "Цвет",
    "cover-editor.toolbar.gradient": "Градиент:",
    "cover-editor.toolbar.stroke": "Обводка",
    "cover-editor.templates.caption.templates": "Шаблоны",
    "cover-editor.object.create.header.caption": "Добавить блок",
    "cover-editor.object.create.header.text": "Добавьте элемент обложки",
    "cover-editor.object.create.button.text": "Текстовые блоки",
    "cover-editor.object.create.button.object": "Объект",
    "cover-editor.object.create.button.image-ai": "AI изображения",
    "cover-editor.object.create.button.image-upload": "Загрузить изображение",
    "cover-editor.object.create.button.templates": "Шаблоны",
    "cover-editor.object.create.object.header.caption": "Выберите объект",
    "cover-editor.object.create.object.all": "все",
    "cover-editor.object.create.object.back": "назад",
    "cover-editor.object.create.object.basic-shapes.header.caption": "Базовые фигуры",
    "cover-editor.object.create.object.age-constraints.header.caption": "Возрастной ценз",
    "cover-editor.object.create.object.arrows.header.caption": "Стрелки",
    "cover-editor.object.create.object.splashes.header.caption": "Пятна",
    "cover-editor.object.settings.alignment": "Выравнивание",
    "cover-editor.object.settings.color": "Цвет",
    "cover-editor.object.settings.gradient": "Градиент",
    "cover-editor.object.settings.text.header.caption": "Текстовый блок",
    "cover-editor.object.settings.text.font-family": "Шрифт",
    "cover-editor.object.settings.text.style": "Начертание",
    "cover-editor.object.settings.text.align": "Выравнивание",
    "cover-editor.object.settings.text.fill": "Заливка",
    "cover-editor.object.settings.shape.header.caption": "Объект",
    "cover-editor.object.settings.shape.fill": "Заливка",
    "cover-editor.object.settings.shape.stroke": "Обводка",
    "cover-editor.object.settings.shape.stroke-width": "Толщина обводки",
    "cover-editor.object.settings.shape.corner-radius": "Скругление углов",
    "cover-editor.object.settings.line.line-type": "Тип линии",
    "cover-editor.object.settings.image.header.caption": "Изображение",
    "cover-editor.object.settings.multiselect.header.caption.selected": "{objects, plural, zero {Выбрано} one {Выбран} two {Выбрано} few {Выбрано} many {Выбрано} other {Выбрано} }",
    "cover-editor.object.settings.multiselect.header.caption.layers": "{objects, plural, zero {слоев} one {слой} two {слоя} few {слоя} many {слоев} other {слоев} }",
    "cover-editor.object.settings.multiselect.button.group": "Группировать слои",
    "cover-editor.object.settings.group.header.caption": "Группа объектов",
    "cover-editor.object.settings.group.button.ungroup": "Разгруппировать слои",
    "cover-editor.object.settings.group.object-colors": "Используемые цвета",
    "cover-editor.object.settings.group.object-tones": "Используемые тона",
    "cover-editor.object.settings.group.general-tone": "Задать общий тон цвета",
    "cover-editor.object.settings.group.button.apply-general-tone": "Применить",
    "cover-editor.object.settings.mask-group.header.caption": "Маска",
    "cover-editor.templates.header.caption": "Шаблоны",
    "cover-editor.templates.button.save-current": "Сохранить текущий",
    "cover-editor.templates.button.apply": "Применить",
    "cover-editor.templates.button.delete": "Удалить",
    "cover-editor.sidebar.button.next": "Далее",
    "cover-editor.completion-modal.export.caption": "Экспортировать",
    "cover-editor.completion-modal.export.description": "Скачать файл .png",
    "cover-editor.completion-modal.export.button.download": "Скачать обложку",
    "cover-editor.completion-modal.more.caption": "Вы также можете",
    "cover-editor.completion-modal.more.description": "Продолжить работу над проектом в разметке или другие действия",
    "cover-editor.completion-modal.more.button.upload-book": "Загрузить рукопись",
    "cover-editor.completion-modal.more.button.edit-book": "Перейти в разметку",
    "cover-editor.completion-modal.more.button.to-my-projects": "Перейти в мои проекты",
    "cover-editor.completion-modal.more.button.to-cover-editor": "Вернуться в редактор",
    "cover-editor.conceptual-assistant.start-modal.title": "Концептуальный помощник",
    "cover-editor.conceptual-assistant.start-modal.description": "Введите аннотацию своей книги и <br>получите идеи для оформления обложки",
    "cover-editor.conceptual-assistant.start-modal.annotation-placeholder": "Моя книга о...",
    "cover-editor.conceptual-assistant.start-modal.form.error": "Проверьте введенные данные",
    "cover-editor.conceptual-assistant.start-modal.form-min-number-characters.error": "Минимальное количества символов",
    "cover-editor.conceptual-assistant.start-modal.form-max-number-characters.error": "Достигнут лимит количества символов",
    "cover-editor.conceptual-assistant.try-button": "Попробовать",
    "cover-editor.conceptual-assistant.view-button": "Посмотреть",
    "cover-editor.conceptual-assistant.title": "Концептуальный <br>помощник",
    "cover-editor.conceptual-assistant.new.description": "Идеи для вашей <br>обложки от нейросети",
    "cover-editor.conceptual-assistant.view.description": "Идея вашей <br>обложки готова!",
    "cover-editor.conceptual-assistant.menu.title": "Концептуальный помощник",
    "cover-editor.conceptual-assistant.menu.annotation": "Аннотация",
    "cover-editor.conceptual-assistant.menu.annotation-placeholder": "Введите аннотацию проекта",
    "cover-editor.conceptual-assistant.menu.target-audience": "Целевая аудитория",
    "cover-editor.conceptual-assistant.menu.target-audience-placeholder": "Введите целевую аудиторию проекта",
    "cover-editor.conceptual-assistant.menu.genre": "Жанр ",
    "cover-editor.conceptual-assistant.menu.genre-placeholder": "Введите жанр проекта",
    "cover-editor.conceptual-assistant.menu.generate-button": "Генерировать идеи",
    "cover-editor.conceptual-assistant.result.generation-error": "Ошибка генерации концепции обложки",
    "cover-editor.conceptual-assistant.result.theme.title": "Тема ",
    "cover-editor.conceptual-assistant.result.genre.title": "Жанр",
    "cover-editor.conceptual-assistant.result.target-audience.title": "Целевая аудитория",
    "cover-editor.conceptual-assistant.result.prompt.title": "Выберите сюжет",
    "cover-editor.conceptual-assistant.result.prompt.image-style.title": "Выберите стиль",
    "cover-editor.conceptual-assistant.result.prompt.image-mood.title": "Настроение",
    "cover-editor.conceptual-assistant.result.prompt.image-age.title": "Эпоха",
    "cover-editor.conceptual-assistant.result.prompt.image-style.color.background": "Цвет фона",
    "cover-editor.conceptual-assistant.result.prompt.image-style.color.accent": "Цвет акцентов",
    "cover-editor.conceptual-assistant.result.prompt.action-button": "Сгенерировать",
    "cover-editor.conceptual-assistant.result.fonts-and-colors.title": "Шрифты и цвета",
    "cover-editor.conceptual-assistant.result.font.style.main": "Основной шрифт",
    "cover-editor.conceptual-assistant.result.font.style.subheading": "Шрифт подзаголовков",
    "cover-editor.conceptual-assistant.result.font.style.secondary": "Второстепенный шрифт",
    "cover-editor.conceptual-assistant.result.fonts-and-colors.action-button": "Применить",
    "cover-editor.conceptual-assistant.result.summary.title": "Резюме",
    "cover-editor.conceptual-assistant.result.reset-button": "Новая идея",
    "cover-editor.conceptual-assistant.result.refresh-button": "Ещё идеи",
    "cover-editor.conceptual-assistant.result.processing": "Генерация идей...",
    "cover-editor.share.cover-published-notification": "Ваша обложка отправлена на модерацию и будет опубликована в сообществе в ближайшее время",
    "cover-editor.image.remove-background": "Удалить фон с изображения",
    "cover-editor.image.remove-background.info": "Удаляет фон с изображения и добавляет его как новый слой",
    "cover-editor.image.removed-background.text": "(без фона)",
    "cover-editor.cover.error-on-save": "Не удалось сохранить изображение",
    "cover-editor.fonts.fonts-loading": "Загружаем...",
    "cover-editor.position": "Расположение",
    "cover-editor.conceptual-assistant.info-block.step-1.title": "Идеи оформления <br>обложки готовы!",
    "cover-editor.conceptual-assistant.info-block.step-1.description": "Следуйте моим <br>рекомендациям <br>для получения <br>отличного результата.",
    "cover-editor.sidebar.tabs.service": "Сервис",
    "cover-editor.sidebar.tabs.community": "Коммьюнити",
    "cover-editor.object.snippet.title": "Сниппет",
    "cover-editor.object.settings.group.button.create-snippet": "Создать сниппет",
    "cover-editor.create-mask": "Создать маску",
    "cover-editor.delete-mask": "Удалить маску",
    "cover-editor.mask": "Маска",
    "cover-editor.mask.add-elements": "Добавьте объекты",
    "cover-editor.mask.mask-element": "Контур маски",
    "cover-editor.create-snippet-modal.title": "Добавить ассет в коммьюнити",
    "cover-editor.create-snippet-modal.category.title": "Тип",
    "cover-editor.create-snippet-modal.category.text": "Текст",
    "cover-editor.create-snippet-modal.category.object": "Объект",
    "cover-editor.create-snippet-modal.subcategory.title": "Вид",
    "cover-editor.cover-snippet.subcategory.text.headers-and-text.title": "Заголовки и текст",
    "cover-editor.cover-snippet.subcategory.text.lists.title": "Списки",
    "cover-editor.cover-snippet.subcategory.text.quotes.title": "Цитаты",
    "cover-editor.cover-snippet.subcategory.objects.basic-shapes.title": "Базовые фигуры",
    "cover-editor.cover-snippet.subcategory.objects.age-constraints.title": "Возрастной ценз",
    "cover-editor.cover-snippet.subcategory.objects.arrows.title": "Стрелки",
    "cover-editor.cover-snippet.subcategory.objects.splashes.title": "Пятна",
    "cover-editor.cover-snippet.default.text.title": "Текст",
    "cover-editor.cover-snippet.default.image.title": "Изображение",
    "cover-editor.cover-snippet.default.rectangle.title": "Прямоугольник",
    "cover-editor.cover-snippet.default.ellipse.title": "Эллипс",
    "cover-editor.cover-snippet.default.shape.title": "Фигура",
    "cover-editor.cover-snippet.default.group.title": "Группа",
    "cover-editor.cover-snippet.default.snippet.title": "Сниппет",
    "cover-editor.create-snippet-modal.is-premium.title": "Премиальный",
    "cover-editor.create-snippet-modal.is-visible-to-users.title": "Доступен пользователя",
    "cover-editor.create-snippet-modal.is-visible-to-other-companies.title": "Доступен другим компаниям",
    "cover-editor.create-snippet-modal.send-for-moderation.button": "Отправить на модерацию",
    "cover-editor.create-snippet-modal.form.error.notification": "Заполните поля",
    "cover-editor.create-snippet-modal.create.error.notification": "Ошибка создания снипета",
    "cover-editor.create-snippet-modal.apply.error.notification": "Произошла ошибка при добавлении снипета",
    "cover-editor.conceptual-assistant-step-2.genre.without-genre": "Без жанра",
    "cover-editor.conceptual-assistant-step-2.genre.fantastic": "Фантастика",
    "cover-editor.conceptual-assistant-step-2.genre.detective": "Детектив",
    "cover-editor.conceptual-assistant-step-2.genre.horror": "Ужасы",
    "cover-editor.conceptual-assistant-step-2.genre.comedy": "Комедия",
    "cover-editor.conceptual-assistant-step-2.genre.tragedy": "Трагедия",
    "cover-editor.conceptual-assistant-step-2.genre.romance": "Романтика",
    "cover-editor.conceptual-assistant-step-2.genre.adventure": "Приключения",
    "cover-editor.conceptual-assistant-step-2.genre.mysticism": "Мистика",
    "cover-editor.conceptual-assistant-step-2.genre.historical-prose": "Историческая проза",
    "cover-editor.conceptual-assistant-step-2.genre.social-prose": "Социальная проза",
    "cover-editor.conceptual-assistant-step-2.genre.fantasy": "Фэнтези",
    "cover-editor.conceptual-assistant-step-2.genre.drama": "Драма",
    "home.breadcrumb.home-page": "Главная страница",
    "home.last-project.new-project": "Новый проект",
    "home.last-project.last-project": "Последний проект",
    "home.menu.my-projects-button": "Мои проекты",
    "home.menu.create-cover-button": "Создать обложку",
    "home.menu.image-generation-button": "AI изображения",
    "home.menu.check-text-button": "Проверить текст",
    "home.menu.referals-button": "Рефералы",
    "home.menu.feedback-button": "Оставить отзыв",
    "home.menu.knowledge-base-button": "База знаний",
    "home.menu.image-editor-button": "Фоторедактор",
    "home.menu.image-enhancement-button": "Улучшение картинок",
    "home.menu.background-removal-button": "Удаление фона",
    "home.menu.platform-covers-button": "Дизайн соц. сетей",
    "home.menu.community-contests-button": "Конкурсы",
    "home.menu.funbums-button": "Создать фанбом",
    "home.unit": "мм",
    "home.new-project-modal.title": "Новый проект",
    "home.new-project-modal.sidebar-section.book": "Книга",
    "home.new-project-modal.sidebar-section.item.book.format-a4": "Формат А4",
    "home.new-project-modal.sidebar-section.item.book.format-a5": "Формат А5",
    "home.new-project-modal.sidebar-section.item.book.format-custom": "Свой формат",
    "home.new-project-modal.sidebar-section.corporate-material": "Корпоративный материал",
    "home.new-project-modal.sidebar-section.item.corporate-material.letter": "Письмо",
    "home.new-project-modal.sidebar-section.item.corporate-material.report": "Отчёт",
    "home.new-project-modal.sidebar-section.item.corporate-material.contract": "Договор",
    "home.new-project-modal.sidebar-section.scientific-publication": "Научная публикация",
    "home.new-project-modal.upload-template": "Загрузить свой шаблон",
    "home.new-project-modal.content.header.books": "Книги",
    "home.new-project-modal.content.header.corporate-material": "Корпоративные материалы",
    "home.new-project-modal.content.header.scientific-publication": "Научные публикации",
    "home.new-project-modal.content.header.format": "Формат",
    "home.community.title": "Сообщество",
    "home.community.filter.published-covers": "Обложки",
    "home.community.filter.published-images": "Изображения",
    "home.community.create-cover.button": "Создать обложку",
    "home.community.generate-images.button": "Генерировать изображения",
    "home.community.unpublish-modal": "Распубликовать?",
    "home.community.unpublish-confirm-notification": "Распубликовано",
    "home.community.empty-state-images": "Здесь будут изображения сообщества",
    "home.community.empty-state-covers": "Здесь будут обложки сообщества",
    "home.promo-menu-buttons.title": "Начните создавать сегодня",
    "home.promo-menu-buttons.description": "Большой спектр инструментов для каждой задачи",
    "home.promo-menu-buttons.button.participate-webinar": "Участвуйте <br>в вебинаре!",
    "home.promo-menu-buttons.button.create-book": "Верстайте <br>книги",
    "home.promo-menu-buttons.button.create-image-generation": "Генерируйте <br>изображения",
    "home.promo-menu-buttons.button.create-cover-idea": "Создавайте <br>идеи для <br>обложек",
    "home.promo-menu-buttons.button.create-cover": "Создавайте <br>обложки",
    "home.promo-menu-buttons.button.upgrade-image": "Улучшайте <br>изображения",
    "home.promo-menu-buttons.button.get-tokens": "Получайте токены <br>за активности",
    "home.select-new-project-type.title": "С чего начнём?",
    "home.select-new-project-type.create-cover.title": "Создать обложку",
    "home.select-new-project-type.create-cover.description": "Нейросеть поможет воплотить <br>все ваши гениальные идеи ",
    "home.select-new-project-type.create-book.title": "Сверстать книгу",
    "home.select-new-project-type.create-book.description": "Создать печатный шедевр <br>из вашей рукописи"
  }
};